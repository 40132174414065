import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';

export const clientSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Client Desk',
      icon: 'evoicon evo-profile',
      subs: [
        {
          label: 'Overview',
          link: '/client-desk/overview',
          id: 'MO7',
        },
        {
          label: 'Find Client',
          link: '/client-desk/find',
          id: 'MFC8',
        },
        {
          label: 'Find Provider',
          link: '/client-desk/provider/find',
          id: 'MFP9M',
        },
        {
          label: 'Create New Client',
          subs: [
            {
              label: 'Individual',
              link: '/client-desk/create/individual',
              id: 'MI10',
            },
            {
              label: 'Corporate',
              link: '/client-desk/create/corporate',
              id: 'MC11',
            },
            {
              label: 'Provider',
              link: '/client-desk/create/provider',
              id: 'MP12',
            },
          ],
          id: 'MCNC9',
        },
      ],
      children: PageChildren.clientChildren(),
      id: 'MCD6',
    },
  ])[0];
