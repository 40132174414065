import { ESystem } from '@Shared/models/index.model';
import { IMenuItem } from '@Shared/models/IMenuItem';
const system = ESystem.process;
export const processSubMenu: IMenuItem[] = [
  {
    label: 'Process',
    icon: 'fas fa-tasks',
    subs: [
      {
        label: 'Find Process',
        link: '/process/process-map/find',
        id: 'MPFP719',
      },
      {
        label: 'Create Process',
        link: '/process/process-map/create',
        id: 'MPCP720',
      },
    ],
    id: 'MPP719',
  },
  {
    label: 'Setup',
    icon: 'evoicon evo-setting',
    subs: [
      {
        label: 'Process Types',
        link: '/process/process-type',
        id: 'MPPT722',
      },
    ],
    id: 'MPS721',
  },
  {
    label: 'Admin',
    icon: 'evoicon evo-admin',
    subs: [
      {
        label: 'User',
        subs: [
          {
            label: 'User',
            link: '/process/admin-desk/user',
            id: 'MPU725',
          },
          {
            label: 'User Group',
            link: '/process/admin-desk/user/group',
            id: 'MPUG726',
          },
          {
            label: 'User Menu',
            link: '/process/admin-desk/user/menus',
            id: 'MPUM727',
          },
          {
            label: 'User Activity',
            link: '/process/admin-desk/user/activities',
            id: 'MPUA728',
          },
          {
            label: 'User Session',
            link: '/process/admin-desk/user/sessions',
            id: 'MPUS729',
          },
          {
            label: 'Web User',
            subs: [
              {
                label: 'Create New',
                link: '/process/admin-desk/web-user',
                id: 'MPCN731',
              },
            ],
            id: 'MPWU730',
          },
        ],
        id: 'MPU724',
      },
    ],
    id: 'MPA723',
  },
];
