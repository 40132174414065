import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { Code, Codes } from '@Shared/models/life/setup/codes/Codes';
import { CodesGroup } from '@Shared/models/life/setup/codes/CodesGroup';
import { ApiService } from './api.service';
import { ICodeDescription, ICodeTitle } from '../Shared/models/index.model';
import { EVFunctions } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class CodeService {
  baseURL = environment.apiBaseUrl + '/rest';
  retryCount = 1;
  noRetry = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private apiService: ApiService) {}

  getNumberingByCompanyCode(company: string) {
    return this.apiService.get(`${this.baseURL}/numbering/${company}`).pipe(retry(this.retryCount));
  }

  getCodesByCodeSubGroup = (codeSubGroup: string) => {
    return this.apiService
      .get<ICodeTitle[]>(`${this.baseURL}/codes/sub/category/${codeSubGroup?.toUpperCase()}`)
      .pipe(
        retry(this.retryCount),
        catchError((r) => of(<ICodeTitle[]>[])),
      );
  };
  getCodesByCodeSubGroupWithCache = (codeSubGroup: string) => {
    return this.apiService
      .getWithLocalCache<ICodeTitle[]>(`${this.baseURL}/codes/sub/category/${codeSubGroup}`)
      .pipe(
        retry(this.retryCount),
        catchError((r) => of(<ICodeTitle[]>[])),
      );
  };

  getCodesByCodeSubGroupAndCat = (codeSubGroup: string, cat: string) => {
    return this.apiService
      .get<ICodeTitle[]>(`${this.baseURL}/codes/sub/category/${codeSubGroup}/${cat}`)
      .pipe(retry(this.retryCount));
  };

  getCodeTitlesByCodeAndCodeSubGroup = (code: string, codeSubGroup: string) => {
    return this.apiService
      .get<ICodeTitle[]>(`${this.baseURL}/codes/titles/${code}/${codeSubGroup}`)
      .pipe(retry(this.retryCount));
  };
  /**
   *
   * @param codeSubGroup code group
   * @param code code cat
   * @returns
   */
  getCodeTitlesByCodeCatAndCodeSubGroup = (codeSubGroup: string, code: string) => {
    return this.apiService
      .get<ICodeTitle[]>(`${this.baseURL}/codes/code_title/group/${codeSubGroup}/${code}`)
      .pipe(retry(this.retryCount));
  };

  getCodeTitleByCodeAndCodeSubGroup = (code: string, codeSubGroup: string) => {
    if (!code) return of<ICodeTitle>({ code, title: null });
    return this.apiService.get<ICodeTitle>(`${this.baseURL}/codes/code/title/${code}/${codeSubGroup}`).pipe(
      retry(this.retryCount),
      catchError((e) => of<ICodeTitle>(null)),
    );
  };

  getCodeTitleByCodeAndSubGroupFactory = (codeSubGroup: string) => (code: string) =>
    this.getCodeTitleByCodeAndCodeSubGroup(code, codeSubGroup).pipe(
      map((r) => EVFunctions.strConcatenator2(code, r?.title)),
    );

  getTitleByCodeAndCodeSubGroup = (code: string, codeSubGroup: string) => {
    return this.apiService
      .get<{ code: string; title: string }>(`${this.baseURL}/codes/code/title/${code}/${codeSubGroup}`)
      .pipe(map((r) => r?.title));
  };
  getTitleByCodeAndCodeSubGroupAsync = (code: string, codeSubGroup: string) => {
    return lastValueFrom(
      this.apiService
        .get<{ code: string; title: string }>(`${this.baseURL}/codes/code/title/${code}/${codeSubGroup}`)
        .pipe(map((r) => r?.title)),
    );
  };

  getCodeGroups = (): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/codes/group`).pipe(
      map((r) => r.sort2('description', true)),
      retry(this.retryCount),
    );
  };

  getAllCodeSubGroups = (): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/codes/subgroups`).pipe(
      map((r) => r.sort()),
      retry(this.retryCount),
    );
  };

  getAllCode = (): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/codes`).pipe(
      map((r) => r.sort()),
      retry(this.retryCount),
    );
  };

  getAllCodeByGroup = (group: string): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/codes/subgroups/${group}`).pipe(
      map((r) => r.sort()),
      retry(this.retryCount),
    );
  };

  getAllCodeByCodeSubGroup = <T = string>(codeSubGroup: string) => {
    return this.apiService
      .get<ICodeTitle<T>[]>(`${this.baseURL}/codes/sub/group/${codeSubGroup}`)
      .pipe(retry(this.retryCount));
  };

  getAllCodesCodeAndTitle = (): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/codes/code/title`).pipe(retry(this.retryCount));
  };

  getAllAccCodes = (): Observable<any> => {
    return this.apiService.get(`${this.baseURL}/acc/codes/desc`).pipe(retry(this.retryCount));
  };

  /**
   * Get list of available screens for a specified group
   * @param data groupCode
   * @returns
   */
  getScreensList = (data: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + '/screen/code/desc/' + data);
  };
  createCodes(codes: any): Observable<Code[]> {
    return this.apiService.post<Code[]>(`${this.baseURL}/codes/`, codes).pipe(retry(this.noRetry));
  }

  createCodesGroup = (codesGroup: any): Observable<CodesGroup> => {
    return this.apiService
      .post<CodesGroup>(`${this.baseURL}/codes/group`, codesGroup)
      .pipe(retry(this.noRetry));
  };

  getCodesByGroup_Subgroup = (group: string, sub_group: string) => {
    return this.apiService.get<ICodeTitle[]>(this.baseURL + '/codes/' + group + '/' + sub_group);
  };

  getStatusCodeByCompanyAndCat = (cat: string, company: string) => {
    return this.apiService.get(this.baseURL + '/status/code/' + company + '/' + cat);
  };

  getStatusCodeByCat = (cat: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + '/status/code/description/' + cat);
  };

  getStatusCodeByCode = (code: string) => {
    return this.apiService.get(this.baseURL + '/status/code/' + code);
  };

  deleteStatusDestinationById = (id: number) => {
    return this.apiService.delete(this.baseURL + '/status/code/destination/' + id);
  };

  // Error handling
  handleError(error: { error: { message: string }; status: any; message: any }) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
  }
}
