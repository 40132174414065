import { ESystem } from '@Shared/models/index.model';
import { IMenuItem } from '@Shared/models/IMenuItem';
import { formatSubMenus } from '@configs/base-functions';
import { paymentSubMenu } from './level-1/payment-desk.menu.config';
import { adminSubMenu } from './level-1/admin-desk.menu.config';
import { agentSubMenu } from './level-1/agent-desk.menu.config';
import { clientSubMenu } from './level-1/client-desk.menu.config';
import { quoteSubMenu } from './level-1/quotation-desk.menu.config';
import { policySubMenu } from './level-1/policy-desk.menu.config';
import { groupSubMenu } from './level-1/group-desk.menu.config';
import { workflowSubMenu } from './level-1/workflow-desk.menu.config';
import { reportSubMenu } from './level-1/report-desk.menu.config';
import { setupSubMenu } from './level-1/setup-desk.menu.config';
const system = ESystem.life;
const idPrefix = 'ML';
const routePrefix = '/life';
export const lifeSubMenu: IMenuItem[] = [
  agentSubMenu(idPrefix, routePrefix),
  clientSubMenu(idPrefix, routePrefix),
  quoteSubMenu(idPrefix, routePrefix),
  policySubMenu(idPrefix, routePrefix),
  groupSubMenu(idPrefix, routePrefix),
  workflowSubMenu(idPrefix, routePrefix),
  paymentSubMenu(idPrefix, routePrefix),
  setupSubMenu(idPrefix, routePrefix),
  reportSubMenu(idPrefix, routePrefix),
  adminSubMenu(idPrefix, routePrefix),
];

formatSubMenus(lifeSubMenu);
