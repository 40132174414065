import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {
  CreateUser,
  UserBox,
  User,
  UserOutOfOffice,
  UserGroup,
  UserBasic,
} from '@Shared/models/life/user/Users';
import { CreateCrmUserGroup } from '@Shared/models/life/user/UserGroup';
import { CreateUserMenu } from '@Shared/models/life/user/UserMenu';
import { ApiService } from '@Services/api.service';
import { CodeService } from '@Services/code.service';
import { UsermenuService } from '../../usermenu/usermenu-extras/usermenu.service';
import { IUserSearchObj, IUserSearchResponse } from './user.interface';
import { FormGroup, FormControl } from '@angular/forms';
import { EndorsementResponse } from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { IUser, IUserProfile } from '@Authentication/auth-extras/authentication.interface';
import { ISearchResponse, ISearchResponse2 } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  URL = environment.apiBaseUrl + '/rest';
  retryCount = 1;
  noRetry = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private apiService: ApiService,
    public codeS: CodeService,
    private userMenuService: UsermenuService,
  ) {}

  getUserByUsername(userName: string) {
    return this.apiService.get<IUserProfile>(this.URL + '/users/user/username/' + userName);
  }

  getUserByUsernameLite(userName: string) {
    return this.search({ userName }).pipe(map((r) => r?.content?.[0]));
  }
  getusersGroup = () => {
    return this.apiService
      .get<
        {
          group: string;
          description: string;
        }[]
      >(`${this.URL}/users/group`)
      .pipe(
        retry(this.retryCount),
        map((r) =>
          r
            .filter((x) => x)
            .map((x) => ({ ...x, code: x.group, title: x.description }))
            .sort3('code'),
        ),
      );
  };
  getUserStatus() {
    return this.codeS.getCodesByCodeSubGroup('USER_STATUS');
  }

  getUserOutOfOffice(userCode: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/out-of/office/${userCode}`).pipe(retry(this.retryCount));
  }

  saveUserOutOfOffice(userOfOfOffice: UserOutOfOffice): Observable<UserOutOfOffice> {
    return this.apiService
      .post<UserOutOfOffice>(`${this.URL}/users/out-of/office`, userOfOfOffice)
      .pipe(retry(this.retryCount));
  }

  getAllUsersGroup = () => {
    return this.apiService.get(`${this.URL}/users/group/all`).pipe(retry(this.retryCount));
  };

  getAllUsersGroupAndCount = () => {
    return this.apiService.get(`${this.URL}/users/group/count`).pipe(retry(this.retryCount));
  };

  getAllUsers = () => {
    return this.apiService.get(`${this.URL}/users/code/full-name`).pipe(retry(this.retryCount));
  };

  getUserBoxByUserCode(userCode: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/box/${userCode}`).pipe(retry(this.retryCount));
  }

  saveUserBox(userBox: UserBox): Observable<UserBox> {
    return this.apiService.post<UserBox>(`${this.URL}/users/box`, userBox).pipe(retry(this.retryCount));
  }

  getAllUserMenus(): Observable<any> {
    return this.userMenuService.getAllUserMenus();
  }

  getusersGroupByBusLine(busLine: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/group/${busLine}`).pipe(retry(this.retryCount));
  }

  getuserId(): Observable<any> {
    return this.apiService.get(`${this.URL}/users/id/fullname`).pipe(retry(this.retryCount));
  }

  getUserByClientNo(clientNo: string) {
    return this.apiService.get<User>(`${this.URL}/users/find/user/${clientNo}`).pipe(retry(this.retryCount));
  }

  getAllUserIdByGroup(group: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/id/fullname/group/${group}`).pipe(retry(this.retryCount));
  }

  getUserByUserId(id: number) {
    return this.apiService.get<CreateUser>(`${this.URL}/users/${id}`).pipe(retry(this.retryCount));
  }

  getuserUserGroupByGroup(group: string) {
    return this.apiService.get<UserGroup>(`${this.URL}/users/group/${group}`).pipe(retry(this.retryCount));
  }

  getuserIdByCompanyCode(companyCode: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/userid/${companyCode}`).pipe(retry(this.retryCount));
  }

  getuserMenuByUserMenu(userMenu: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/menu/${userMenu}/L`).pipe(retry(this.retryCount));
  }

  getuserIdAndnameByCompanyCode(companyCode: string): Observable<any> {
    return this.apiService
      .get(`${this.URL}/users/userid/fullname/${companyCode}`)
      .pipe(retry(this.retryCount));
  }
  getUserCodeAndFullnameByCompanyCode(companyCode: string): Observable<any> {
    return this.apiService.get(`${this.URL}/users/code/fullname/${companyCode}`).pipe(retry(this.retryCount));
  }

  createUser(createUser: any) {
    return this.apiService
      .post(`${this.URL}/users/`, createUser, {
        options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      })
      .pipe(map((r) => new EndorsementResponse<CreateUser>(r)));
  }

  updateUser(id: number, user: CreateUser) {
    return this.apiService
      .put(`${this.URL}/users/update/user/${id}`, user, {
        options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      })
      .pipe(map((r) => new EndorsementResponse<CreateUser>(r)));
  }
  updateUserAccess(id: number, user: Partial<User>) {
    return this.apiService
      .put(`${this.URL}/users/update/user/access/${id}`, user, {
        options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      })
      .pipe(map((r) => new EndorsementResponse<User>(r)));
  }
  updateUserInfo(id: number, user: User) {
    return this.apiService.put<User>(`${this.URL}/users/update/${id}`, user).pipe(retry(this.noRetry));
  }
  updateUserBasicInfo(user: UserBasic) {
    return this.apiService.put<User>(`${this.URL}/users/user/basic-info`, user).pipe(retry(this.noRetry));
  }

  uploadProfilePicture(formData: FormData) {
    return this.apiService.post(`${this.URL}/document`, formData).pipe(retry(this.noRetry));
  }
  /**
   *
   * @param userName
   * @param status
   * @returns observable
   */
  updateUserStatus(userName: string, status: string) {
    return this.apiService
      .post(`${this.URL}/users/change-status/${userName}/${status}`)
      .pipe(retry(this.noRetry));
  }

  createUserGroup(createUserGroup: CreateCrmUserGroup) {
    return this.apiService
      .postWithEndorsment<CreateCrmUserGroup>(`${this.URL}/users/group`, createUserGroup)
      .pipe(retry(this.noRetry));
  }

  updateUserGroup(createUserGroup: CreateCrmUserGroup) {
    return this.apiService
      .putWithEndorsment<CreateCrmUserGroup>(
        `${this.URL}/users/update/group/${createUserGroup.crmUserGroup.id}`,
        createUserGroup,
      )
      .pipe(retry(this.noRetry));
  }

  createLifeUserMenu(createUserMenu: any): Observable<CreateUserMenu> {
    return this.apiService
      .post<CreateUserMenu>(`${this.URL}/users/menu/L`, createUserMenu)
      .pipe(retry(this.noRetry));
  }

  search(query: IUserSearchObj, useCache = false) {
    return (
      useCache
        ? this.apiService.getWithLocalCache<IUserSearchResponse>
        : this.apiService.get<IUserSearchResponse>
    )(`${this.URL}/users/search`, query);
  }

  deleteTiers = (id: number): Observable<any> => {
    return this.apiService.deleteText(`${this.URL}/users/tier/${id}`);
  };

  deleteCompany = (id: number): Observable<any> => {
    return this.apiService.deleteText(`${this.URL}/users/company/${id}`);
  };

  deleteBranch = (id: number): Observable<any> => {
    return this.apiService.deleteText(`${this.URL}/users/branch/${id}`);
  };

  deletePayout = (id: number): Observable<any> => {
    return this.apiService.deleteText(`${this.URL}/users/payout/${id}`);
  };

  deleteWorkingTime = (id: number): Observable<any> => {
    return this.apiService.deleteText(`${this.URL}/users/working/times/${id}`);
  };

  /**
   * Calls the endpoint that gets Web Access Data
   * @param clientNo
   * @returns
   */
  getWebAccessData(clientNo: string) {
    return this.apiService.get(this.URL + `/web/access/${clientNo}`);
  }

  /**
   * Resend Web access credential to user
   * @param username
   * @returns
   */
  resendLoginCredentials(username: string) {
    return this.apiService.post(`${this.URL}/web/access/resend/login-details/${username}`);
  }

  searchStatusHistory(query) {
    return this.apiService.get(`${this.URL}/users/status/history/search`, query);
  }

  searchUserAccess(query: IUserSearchObj) {
    return this.apiService.get<ISearchResponse2<User>>(`${this.URL}/users/access/search`, query);
  }

  searchUsers(query: IUserSearchObj) {
    return this.apiService.get<ISearchResponse2<CreateUser>>(`${this.URL}/users/search`, query);
  }

  searchAllUsers() {
    return this.apiService.get<ISearchResponse2<User>>(`${this.URL}/users/search`);
  }

  get userBranchFormStructure() {
    return new FormGroup({
      id: new FormControl(),
      userCode: new FormControl(),
      branches: new FormControl(),
      createdBy: new FormControl(),
      deleted: new FormControl(false),
    });
  }
  get userCompanyFormStructure() {
    return new FormGroup({
      id: new FormControl(),
      code: new FormControl(),
      appraisalCompany: new FormControl(),
      userCode: new FormControl(),
      createdBy: new FormControl(),
      deleted: new FormControl(false),
    });
  }
  get userWorkingTimesFormStructure() {
    return new FormGroup({
      createdBy: new FormControl(),
      closeTime: new FormControl(),
      openTime: new FormControl(),
      day: new FormControl(),
      id: new FormControl(),
      deleted: new FormControl(false),
    });
  }
  get userTiersFormStructure() {
    return new FormGroup({
      createdBy: new FormControl(),
      currCode: new FormControl(),
      deleted: new FormControl(false),
      id: new FormControl(),
      limit: new FormControl(),
      tierCategory: new FormControl(),
      tierCode: new FormControl(),
      userCode: new FormControl(),
    });
  }
}
