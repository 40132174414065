import { ESystem } from '@Shared/models/index.model';
import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem';
import { appRoutes } from '../app-routes-configs/app-routes.config';
import { actuarialSubMenu } from './actuarial.menu.config';
import { crmSubMenu } from './crm.menu.config';
import { financeSubMenu } from './finance.menu.config';
import { generalSubMenu } from './general.menu.config';
import { healthSubMenu } from './health.menu.config';
import { lifeSubMenu } from './life.menu.config';
import { processSubMenu } from './process.menu.config';
import { environment } from '@environments/environment';

const configMainMenu: IMenuItem[] = [
  {
    icon: 'fa fa-heart',
    id: 'ML1',
    label: 'Life',
    link: appRoutes.life.pub,
    subs: lifeSubMenu,
    system: ESystem.life,
  },
  {
    icon: 'fa fa-truck',
    id: 'MG169',
    label: 'General',
    link: appRoutes.general.pub,
    subs: generalSubMenu,
    system: ESystem.general,
  },
  {
    icon: 'fa fa-heartbeat',
    id: 'MH341',
    label: 'Health',
    link: appRoutes.health.pub,
    subs: healthSubMenu,
    system: ESystem.health,
  },
  {
    icon: 'fa fa-signal',
    id: 'MA476',
    label: 'Actuarial',
    link: appRoutes.act.pub,
    subs: actuarialSubMenu,
    system: ESystem.actuarial,
  },
  {
    icon: 'fa fa-chart-pie',
    id: 'MC566',
    label: 'CRM',
    link: appRoutes.crm.pub,
    subs: crmSubMenu,
    system: ESystem.crm,
  },
  // {
  //   icon: 'fa fa-chart-bar',
  //   id: 'MA625',
  //   label: 'Analytics',
  //   link: appRoutes.analytics.pub,
  //   subs: analyticsSubMenu,
  //   system: ESystem.analytics,
  // },
  {
    icon: 'fa fa-chart-line',
    id: 'MAM635',
    label: 'ERM',
    link: appRoutes.assets.pub,
    subs: [],
    system: ESystem.assets,
  },
  {
    icon: 'fas fa-wallet',
    id: 'MF636',
    label: 'Finance',
    link: appRoutes.finance.pub,
    subs: financeSubMenu,
    system: ESystem.finance,
  },
  {
    icon: 'fa fa-clone',
    id: 'MD748',
    label: 'DBCloner',
    link: appRoutes.dbcloner.pub,
    subs: [],
    system: ESystem.dBCloner,
  },
  {
    icon: 'fa fa-user',
    id: 'MH749',
    label: 'Human',
    link: appRoutes.human.pub,
    subs: [],
    system: ESystem.human,
  },
  {
    icon: 'fa fa-cogs',
    id: 'MP732',
    label: 'Process',
    link: appRoutes.process.pub,
    subs: processSubMenu,
    system: ESystem.process,
  },
];
// const configMainMenu: Map<ESystem, IMenuItem> = new Map([
//   [
//     ESystem.life,
//     {
//       icon: 'fa fa-heart',
//       id: 'ML1',
//       label: 'Life',
//       link: appRoutes.life.pub,
//       subs: lifeSubMenu,
//       system: ESystem.life,
//     },
//   ],
//   [
//     ESystem.general,
//     {
//       icon: 'fa fa-truck',
//       id: 'MG169',
//       label: 'General',
//       link: appRoutes.general.pub,
//       subs: generalSubMenu,
//       system: ESystem.general,
//     },
//   ],
//   [
//     ESystem.health,
//     {
//       icon: 'fa fa-heartbeat',
//       id: 'MH341',
//       label: 'Health',
//       link: appRoutes.health.pub,
//       subs: healthSubMenu,
//       system: ESystem.health,
//     },
//   ],
//   [
//     ESystem.actuarial,
//     {
//       icon: 'fa fa-signal',
//       id: 'MA476',
//       label: 'Actuarial',
//       link: appRoutes.act.pub,
//       subs: actuarialSubMenu,
//       system: ESystem.actuarial,
//     },
//   ],
//   [
//     ESystem.crm,
//     {
//       icon: 'fa fa-chart-pie',
//       id: 'MC566',
//       label: 'CRM',
//       link: appRoutes.crm.pub,
//       subs: crmSubMenu,
//       system: ESystem.crm,
//     },
//   ],
//   [
//     ESystem.analytics,
//     {
//       icon: 'fa fa-chart-bar',
//       id: 'MA625',
//       label: 'Analytics',
//       link: appRoutes.analytics.pub,
//       subs: analyticsSubMenu,
//       system: ESystem.analytics,
//     },
//   ],
//   [
//     ESystem.assets,
//     {
//       icon: 'fa fa-chart-line',
//       id: 'MAM635',
//       label: 'Assets Management',
//       link: appRoutes.assets.pub,
//       subs: [],
//       system: ESystem.assets,
//     },
//   ],
//   [
//     ESystem.finance,
//     {
//       icon: 'fas fa-wallet',
//       id: 'MF636',
//       label: 'Finance',
//       link: appRoutes.finance.pub,
//       subs: financeSubMenu,
//       system: ESystem.finance,
//     },
//   ],
//   [
//     ESystem.dBCloner,
//     {
//       icon: 'fa fa-clone',
//       id: 'MD748',
//       label: 'DBCloner',
//       link: appRoutes.dbcloner.pub,
//       subs: [],
//       system: ESystem.dBCloner,
//     },
//   ],
//   [
//     ESystem.human,
//     {
//       icon: 'fa fa-user',
//       id: 'MH749',
//       label: 'Human',
//       link: appRoutes.human.pub,
//       subs: [],
//       system: ESystem.human,
//     },
//   ],
// ]);

//#region ARRAY TO OBJ
// function formatter(menus: IMenuItem[]) {
//   const obj = {};
//   for (const menu of menus) {
//     const o: any = { id: menu.id };
//     if (menu.subs?.length) o.subs = formatter(menu.subs) as any;
//     if (menu.buttons?.length) o.buttons = formatter(menu.buttons) as any;
//     obj[menu.label.split(' ').join('')] = o;
//   }
//   return obj;
//   console.log(obj);
// }

// console.log(formatter(actuarialSubMenu));
// console.log(formatter(analyticsSubMenu));
// console.log(formatter(crmSubMenu));
// console.log(formatter(financeSubMenu));
// console.log(formatter(generalSubMenu));
// console.log(formatter(healthSubMenu));
// console.log(formatter(lifeSubMenu));
// debugger;
//#endregion
// console.log('MENUITEMS', configMainMenu);

let idMAP: { [id: string]: string } = {};
function checkForDuplicateIDS(menu = configMainMenu, parentLabel?: string) {
  for (const item of menu) {
    if (idMAP[item.id]) {
      console.error(
        idMAP[item.id] + ` and ${item.label} - ${parentLabel} have duplicate ids - ${item.id}`
      );
    } else {
      idMAP[item.id] = item.label + (parentLabel ? ` - ${parentLabel}` : '');
    }
    if (item.subs?.length) checkForDuplicateIDS(item.subs, idMAP[item.id]);
  }
}
if (!environment.production) {
  checkForDuplicateIDS();
  idMAP = undefined;
}

for (const moduleMenu of Array.from(configMainMenu.values())) {
  // debugger;
  for (let i = 0; i < moduleMenu?.subs?.length; i++) {
    const mi = moduleMenu?.subs[i];
    mi.submenuPosition = moduleMenu?.subs?.length / 2 > i ? 'after' : 'before';
  }
  moduleMenu.systemIcon = moduleMenu.icon;
}
export function getConfigMainMenu(allowedModules?: ESystem[]) {
  // debugger;
  if (allowedModules?.length)
    return allowedModules.map((bus) => new MenuItem(configMainMenu.find((x) => x.system == bus)));
  else return configMainMenu?.map((m) => new MenuItem(m));
}

// let j = 1;
// function getID(m: MenuItem) {
//   return (
//     'M' +
//     m.label
//       ?.split(' ')
//       ?.map((x) => x[0])
//       ?.join('') +
//     j++
//   );
// }
// function idier(m: any) {
//   m.id = getID(m);
//   if (m.subs)
//     for (const menu of m.subs) {
//       idier(menu);
//     }
// }
// for (const m of configMainMenu) {
//   idier(m);
// }
