import { IMenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { ESystem } from '@Shared/models/index.model';
import { appRoutes } from '../app-routes-configs/app-routes.config';
import { PageChildren } from './children.config';
const system = ESystem.finance;
import { formatSubMenus } from '@configs/base-functions';
import { paymentSubMenu } from './level-1/payment-desk.menu.config';
import { adminSubMenu } from './level-1/admin-desk.menu.config';
const idPrefix = 'MF';
const routePrefix = '/finance';
export const financeSubMenu: IMenuItem[] = [
  {
    label: 'Operations',
    icon: 'fas fa-cogs ',
    subs: [
      {
        label: 'Find Policy',
        link: '/finance/policy-desk/find',
        children: PageChildren.policyChildren(system),
        id: 'MFP638',
      },
      {
        label: 'Find Agent',
        link: '/finance/agent-desk/find',
        children: PageChildren.agentChildren(system),
        id: 'MFA639',
      },
      {
        label: 'Find Client',
        link: '/finance/client-desk/find',
        children: PageChildren.clientChildrenLite(system),
        id: 'MFC640',
      },
      {
        label: 'Find Provider',
        link: '/finance/client-desk/provider/find',
        children: PageChildren.providerChildren(system),
        id: 'MFP641M',
      },
      {
        label: 'Find Quotation',
        link: '/finance/quotation-desk/find',
        children: PageChildren.quotationChildren(system),
        id: 'MQ641',
      },
    ],
    id: 'MO637',
  },
  paymentSubMenu(idPrefix, routePrefix),
  {
    label: 'Receipting Desk ',
    icon: 'fas fa-wallet ',
    subs: [
      {
        label: 'Overview',
        link: '/finance/receipting-desk/overview',
        id: 'MO653',
      },
      {
        label: 'Find Receipt',
        link: '/finance/payment-desk/find-receipt',
        id: 'MFR648M',
      },
      {
        label: 'Unposted',
        link: '/finance/receipting-desk/unposted',
        id: 'MU654',
      },
      {
        label: 'Create New',
        link: '/finance/receipting-desk/create',
        id: 'MCN655',
      },
      {
        label: 'Unreconciled',
        link: '/finance/receipting-desk/unreconciled',
        id: 'MU656',
      },
      {
        label: 'Direct Debit Feedback',
        link: '/finance/receipting-desk/direct-debit-feedback',
        id: 'MDDF657',
      },
    ],
    children: PageChildren.receiptChildren(system),
    id: 'MRD652',
  },
  {
    label: 'Accounting Desk',
    icon: 'fas fa-landmark ',
    subs: [
      {
        label: 'Bank',
        subs: [
          {
            label: 'Bank Accounts',
            link: '/finance/account/bank/bank-account',
            id: 'MBA659',
          },
          {
            label: 'Bank Accounts Reconciliation',
            link: '/finance/account/bank/bank-account-reconciliation',
            id: 'MBAR660',
          },
          {
            label: 'Cheque Writing',
            link: '/finance/account/bank/cheque-writing',
            id: 'MCW661',
          },
          {
            label: 'Local Bank List',
            link: '/finance/account/bank/bank',
            id: 'MLBL662',
          },
          {
            label: 'Pending Bank Payout',
            link: '/finance/account/bank/pending-bank-payout',
            id: 'MFPBP663M',
          },
          {
            label: 'Pending Bank Feedback',
            link: '/finance/account/bank/pending-bank-feedback',
            id: 'MFPBF664M',
          },
        ],
        id: 'MB658',
      },
      {
        label: 'Accounts',
        subs: [
          {
            label: 'Account Code',
            link: '/finance/setups/accounts/accounts',
            id: 'MAC664',
          },
          {
            label: 'General Ledger Mapping',
            link: '/finance/setups/accounts/general-ledger-mapping',
            id: 'MGLM665',
          },
          {
            label: 'Payment Outward',
            link: '/finance/setups/accounts/payment-outward',
            id: 'MPO666',
          },
          {
            label: 'Adjustment Account Mapping',
            link: '/finance/setups/accounts/adjustment-account-mapping',
            id: 'MAAM667',
          },
          {
            label: 'Account Ledger',
            link: '/finance/setups/accounts/account-ledger',
            id: 'MAL668',
          },
          {
            label: 'Budget',
            link: '/finance/setups/accounts/financial-statement/budget',
            id: 'MB669',
          },
          {
            label: 'Transaction Reason Mapping',
            link: '/finance/setups/accounts/transaction-reason-mapping',
            id: 'MTRM670',
          },
          {
            label: 'Account Sign',
            link: '/finance/setups/accounts/account-sign',
            id: 'MFAS671M',
          },
          {
            label: 'Financial Statement',
            subs: [
              {
                label: 'Account Line',
                link: '/finance/setups/accounts/financial-statement/account-line',
                id: 'MAL672',
              },
              {
                label: 'Financial Statement',
                link: '/finance/setups/accounts/financial-statement/financial-statement',
                id: 'MFS674',
              },
            ],
            id: 'MFS671',
          },
          {
            label: 'Trial',
            subs: [
              {
                label: 'Trial Balance',
                link: '/finance/setups/accounts/trial-balance',
                id: 'MFTB712M',
              },
              {
                label: 'Global',
                link: '/finance/setups/accounts/global',
                id: 'MFG713M',
              },
            ],
            id: 'MFTB711M',
          },
          {
            label: 'Payout Analysis',
            id: 'MFPE712M',
            link: '/finance/setups/accounts/payout-analysis',
          },
        ].sortByFieldLength('subs', true),
        id: 'MA663',
      },
      {
        label: 'Create Account Journal',
        link: '/finance/account/create',
        id: 'MCAJ675',
      },
      {
        label: 'Financial Statement',
        id: 'MFS676M',
        link: '/finance/account/financial-statement',
      },
      {
        label: 'Control',
        link: '/finance/account/control',
        // subs: [
        //   {
        //     label: 'Payment Outwards',
        //     link: '/finance/payment-desk/authorize-payment-outward',
        //     id: 'MPO678',
        //   },
        //   {
        //     label: 'Policy Accounting',
        //     link: '/finance/payment-desk/authorize-policy-account',
        //     id: 'MPA679',
        //   },
        //   {
        //     label: 'Receipt',
        //     link: '/finance/account/controls/receipt',
        //     id: 'MR680',
        //   },
        //   {
        //     label: 'Suspense Switch',
        //     link: '/finance/account/controls/auth-suspense-switch',
        //     id: 'MSS681',
        //   },
        //   {
        //     label: 'Commission General Adjustment',
        //     link: '/finance/account/controls/auth-com-adjustment',
        //     id: 'MCGA682',
        //   },
        // ],
        id: 'MC677',
      },
      {
        label: 'Cost Accounting',
        link: '/finance/cash-report',
        subs: [
          {
            label: 'Chart of Cost Type',
            link: '/finance/account/cost-accounting/cost-type',
            id: 'MCoCT684',
          },
          {
            label: 'Chart of Cost Centers',
            link: '/finance/account/cost-accounting/cost-center',
            id: 'MCoCC685',
          },
          {
            label: 'Chart of Cost Objects',
            link: '/finance/account/cost-accounting/cost-objects',
            id: 'MCoCO686',
          },
          {
            label: 'Cost Allocation',
            link: '/finance/account/cost-accounting/cost-allocation',
            id: 'MCA687',
          },
          {
            label: 'Cost Budgets',
            link: '/finance/account/cost-accounting/cost-budget',
            id: 'MCB688',
          },
          {
            label: 'Cost Journal',
            id: 'MCJ689',
          },
          {
            label: 'Expense Analysis',
            id: 'MEA690',
          },
        ],
        id: 'MCA683',
      },
      {
        label: 'Account Sign',
        link: '/finance/account/account-sign',
        id: 'MFAS676M',
      },
      {
        label: 'Failed Ledger Entries',
        link: '/finance/account/failed-ledger-entries',
        id: 'MFFLE677M',
      },
      {
        label: 'Reverse Transaction',
        link: '/finance/account/reverse-transactions',
        id: 'MFRT678M',
      },
      {
        label: 'Tax',
        id: 'MFT679M',
        link: '/finance/account/tax',
      },
    ],
    id: 'MAD657',
  },
  {
    label: 'Expense Desk',
    link: '/finance/expense-desk',
    icon: 'fas fa-check-to-slot ',
    subs: [
      {
        label: 'Overview',
        link: '/finance/expense-desk/overview',
        id: 'MFO680M',
      },
      {
        label: 'Find Expense',
        link: '/finance/expense-desk/find-expense',
        id: 'MFFE681M',
      },
      {
        label: 'Create Expense',
        link: '/finance/account/create-expense-journal',
        id: 'MFCE682M',
      },
      {
        label: 'Petty Cash',
        id: 'MPC684M',
        link: '/finance/account/petty-cash',
      },
    ],
    id: 'MFED679M',
  },
  {
    label: 'Asset Desk',
    link: '/finance/asset-desk',
    icon: 'fas fa-car ',
    subs: [
      {
        label: 'Overview',
        link: '/finance/asset-desk/overview',
        id: 'MO191M',
      },
      {
        label: 'Find Asset',
        link: '/finance/asset-desk/find',
        id: 'MFA192M',
      },
      {
        label: 'Create new',
        link: '/finance/asset-desk/create',
        id: 'MCn193M',
      },
      {
        label: 'Company Assets',
        id: 'MFCA675M',
        subs: [
          {
            label: 'Fixed Assets',
            id: 'MFX676M',
            subs: [
              {
                label: 'Property',
                id: 'MP680M',
                subs: [
                  {
                    label: 'Find Property',
                    id: 'MFP691M',
                    link: '/finance/company-assets/property/find',
                  },
                  {
                    label: 'Create Property',
                    id: 'MCP692M',
                    link: '/finance/company-assets/property',
                  },
                ],
              },
              {
                label: 'Allocation',
                id: 'MAL681M',
                link: '/finance/company-assets/allocation',
              },
            ],
          },
          {
            label: 'Financial Assets',
            id: 'MFN681M',
            subs: [
              {
                label: 'Bonds',
                id: 'MB677M',
                subs: [
                  {
                    label: 'Find Bonds',
                    id: 'MFB678M',
                    link: '/finance/company-assets/bonds/find',
                  },
                  {
                    label: 'Create Bonds',
                    id: 'MCB679M',
                    link: '/finance/company-assets/bonds',
                  },
                ],
              },
              {
                label: 'Equity',
                id: 'ME678M',
                subs: [
                  {
                    label: 'Find Equity',
                    id: 'MFE679M',
                    link: '/finance/company-assets/equity/find',
                  },
                  {
                    label: 'Create Equity',
                    id: 'MCE680M',
                    link: '/finance/company-assets/equity',
                  },
                ],
              },
              {
                label: 'Money',
                id: 'MM679M',
                subs: [
                  {
                    label: 'Find Money',
                    id: 'MFM680M',
                    link: '/finance/company-assets/money/find',
                  },
                  {
                    label: 'Create Money',
                    id: 'MCM681M',
                    link: '/finance/company-assets/money',
                  },
                ],
              },
              {
                label: 'Portfolio',
                id: 'MPO682M',
                link: '/finance/company-assets/portfolio',
              },
            ],
          },
        ],
        //link: '/finance/account/company-assets',
      },
    ],
    children: PageChildren.assetChildren(system),
    id: 'MAD190M',
  },
  {
    label: 'Workflow Desk',
    icon: 'fas fa-tasks ',
    subs: [
      {
        label: 'Overview',
        link: '/finance/workflow-desk/overview',
        id: 'MO692',
      },
      {
        label: 'Find Workflow Task',
        link: '/finance/workflow-desk/find',
        id: 'MFWT693',
      },
      {
        label: 'Create New',
        link: '/finance/workflow-desk/create',
        subs: [
          {
            label: 'Task',
            link: '/finance/workflow-desk/task',
            id: 'MT695',
          },
          {
            label: 'Schedule',
            link: '/finance/workflow-desk/schedule',
            id: 'MS696',
          },
        ],
        id: 'MCN694',
      },
    ],
    children: PageChildren.workflowChildren(system),
    id: 'MW691',
  },
  {
    label: 'Set-ups',
    icon: 'evoicon evo-setting',
    subs: [
      {
        label: 'Allocation Setup',
        link: '/finance/setups/allocation-setup',
        id: 'MAS698',
      },
      {
        label: 'Bank',
        subs: [
          {
            label: 'Bank',
            link: '/finance/setups/accounts/bank/bank',
            id: 'MB700',
          },
          {
            label: 'Sort Code',
            link: '/finance/setups/accounts/bank/sort-code',
            id: 'MSC701',
          },
          {
            label: 'Bank Accounts',
            link: '/finance/setups/accounts/bank/bank-accounts',
            id: 'MBA702',
          },
          {
            label: 'Bank Charges',
            link: '/finance/setups/bank/bank-charges',
            id: 'MBC708',
          },
        ],
        id: 'MB699',
      },
      {
        label: 'IFRS',
        subs: [
          {
            label: 'Contact Grouping',
            link: '/finance/setups/ifrs/contract-grouping',
            id: 'MFCG539M',
          },
          {
            label: 'Approach Mapping',
            link: '/finance/setups/ifrs/approach-mapping',
            id: 'MFAM542M',
          },
          {
            label: 'Coverage Period',
            id: 'MFCP543M',
          },
          {
            label: 'Risk Adjustment',
            link: '/finance/setups/ifrs/risk-adjustment',
            id: 'MFRA544M',
          },
          {
            label: 'Discount',
            link: '/finance/setups/ifrs/discount',
            id: 'MFD545M',
          },
        ],
        id: 'MFI538M',
      },
      {
        label: 'Account Setup',
        id: 'MAS703',
      },
      {
        label: 'Depreciation',
        id: 'MD703M',
        link: '/finance/setups/depreciation',
      },
      {
        label: 'Expense Setup',
        link: '/finance/setups/expense-setup',
        id: 'MES704',
      },
      {
        label: 'Cost Center Setup',
        link: '/finance/setups/cost-center-setup',
        id: 'MCCS705',
      },
      {
        label: 'Task Setup',
        link: '/finance/setups/task-setup/index',
        id: 'MTS706',
      },
      {
        label: 'Codes',
        id: 'MFC707M',
        subs: [
          {
            label: 'List Values',
            link: '/finance/setups/code/parameters',
            id: 'MC708M',
          },
          {
            label: 'Premium Frequencies',
            link: '/finance/setups/code/premfreq',
            id: 'MPF709M',
          },
          {
            label: 'Premium Payment Method',
            link: '/finance/setups/code/prempaymthd',
            id: 'MPPM710M',
          },
          {
            label: 'Ledgers',
            link: '/finance/setups/code/ledger',
            id: 'MFLM711M',
          },
        ],
      },
      {
        label: 'Process Design',
        subs: [
          {
            label: 'Control',
            link: '/finance/setups/process-design/control',
            id: 'MFC712M',
          },
        ],
        id: 'MFPPM711M',
      },
    ].sortByFieldLength('subs', true),
    id: 'MS697',
  },
  {
    label: 'Reports',
    icon: 'evoicon evo-blankdoc',
    subs: [
      {
        label: 'Create/Run',
        link: '/finance/report',
        id: 'MC708',
      },
      {
        label: 'Batch',
        link: '/finance/report/batch',
        id: 'MB709',
      },
    ],
    children: PageChildren.reportChildren(system),
    id: 'MR707',
  },
  adminSubMenu(idPrefix, routePrefix),
];

formatSubMenus(financeSubMenu);
