import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { ISearchResponse, ISearchResponse2 } from '@Shared/models/index.model';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import {
  IUADeletionPayload,
  IUASearchObj,
  IUASearchResponse,
  UserActivity,
} from './user-activity.model';

@Injectable({
  providedIn: 'root',
})
export class UserActivityEndpointService {
  private baseURL = environment.apiBaseUrl + '/rest/users/activity/log/';
  constructor(private apiService: ApiService) {}
  submitUA = (uas: UserActivity[]) => {
    return this.apiService.post(`${this.baseURL}`, uas);
  };
  clear = (uas: IUADeletionPayload[]) => {
    return this.apiService.deleteWithBody(`${this.baseURL}clear`, uas);
  };
  search = (query?: IUASearchObj) => { 
    return this.apiService.get<ISearchResponse2<IUASearchResponse>>(`${this.baseURL}search`, query);
  };
}
