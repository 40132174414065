import { KeyValue } from '@angular/common';
import { MenuPositionX } from '@angular/material/menu';
import { ESystem } from '@Shared/models/index.model';
import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem'; 

export interface IUserMenuConfig {
  authBy: string;
  authOn: string;
  description: string;
  userMenu: string;
  updatedOn: string;
  id: string;
}

export interface IUserMenuConfigDetail {
  /**
   * Id generated by the database server
   */
  id?: number;
  /**
   * If the module should be viewed
   */
  viewAccess: boolean;
  /**
   * If the module should be editable
   */
  editAccess: boolean;
  /**
   * If the module has either view or edit access
   */
  access?: boolean;
  authBy?: string;
  authOn?: string;
  /**
   * ESystem that the menu belongs in
   */
  module: ESystem;
  /**
   * Name of the menu item / page
   */
  page: string;
  /**
   * Menu's parentID
   */
  section: string;
  /**
   * Menu's frontend generated ID
   */
  slug: string;
  userMenuConfigId?: number;
}
export interface IUserMenuConfigMap {
  [module: string]: {
    [sectorSlug: string]: IUserMenuConfigDetail;
  };
}
export interface IMiniUserMenuConfigDetail {
  viewAccess: boolean;
  editAccess: boolean;
  page: string;
  // slug: string;
}

export interface IUserMenuConfigSimpleIDMap {
  [menuId: string]: IMiniUserMenuConfigDetail;
}

export interface IUserMenu {
  userMenuConfig: IUserMenuConfig;
  userMenuConfigDetails: IUserMenuConfigDetail[];
}

export class MenuItemPermission extends MenuItem {
  access: boolean;
  breadcrumbs: KeyValue<string, string>[];
  breadcrumbsStr: string;
  dbID: number;
  disabled: boolean;
  editAccess: boolean;
  public get _editAccess(): boolean {
    console.log(this.editAccess);
    return this.editAccess;
  }
  public set _editAccess(value: boolean) {
    this.editAccess = value;
    this.toggleEditAllSubMenus();
  }
  hasSub: boolean;
  icon: string;
  id: string;
  index: number;
  isDivider: boolean;
  label: string;
  labelLowerCase: string;
  link: string;
  parentID: string;
  submenuPosition: MenuPositionX;
  subs: any;
  system: ESystem;
  systemIcon: string;
  systemLink: string;
  viewAccess: boolean;
  public get _viewAccess(): boolean {
    return this.viewAccess;
  }
  public set _viewAccess(value: boolean) {
    this.viewAccess = value;
    this.toggleViewAllSubMenus();
  }

  constructor(menuItem: IMenuItem, parent?: IMenuItem) {
    super(menuItem, parent);
    this.subs = menuItem?.subs?.filter?.((x) => x.label)?.map((x) => new MenuItemPermission(x, menuItem));
    this.hasSub = !!menuItem?.subs?.length;
  }
  toggle() {
    this.disabled = !this.disabled;
  }
  toggleView() {
    this.viewAccess = !this.viewAccess;
    if (this.editAccess) this.viewAccess = true;
  }
  toggleCreate() {
    this.editAccess = !this.editAccess;
    if (this.editAccess) this.viewAccess = true;
  }
  allowEditAllSubMenus() {
    this.subs?.forEach((x) => x?.allowEditAllSubMenus());
    //debugger;
    this.viewAccess = true;
    this.editAccess = true;
  }
  disableEditAllSubMenus() {
    this.subs?.forEach((x) => x?.disableEditAllSubMenus());
    this.editAccess = false;
  }
  allowViewAllSubMenus() {
    this.subs?.forEach((x) => x?.allowViewAllSubMenus());
    //debugger;
    this.viewAccess = true;
    this.editAccess = false;
  }
  disableViewAllSubMenus() {
    this.subs?.forEach((x) => x?.disableViewAllSubMenus());
    this.viewAccess = false;
    this.editAccess = false;
  }
  toggleEditAllSubMenus() {
    // if (this.editAccess == undefined) return;
    !this.editAccess ? this.allowEditAllSubMenus() : this.disableEditAllSubMenus();
    console.log(this.subs);
  }
  toggleViewAllSubMenus() {
    // if (this.viewAccess == undefined) return;
    // debugger
    !this.viewAccess ? this.allowViewAllSubMenus() : this.disableViewAllSubMenus();
    console.log(this.subs);
  }
  allowAuthorization() {
    this.viewAccess = true;
    this.editAccess = true;
  }
  disableAuthorization() {
    this.viewAccess = false;
    this.viewAccess = false;
  }
  toggleAuthorization() {
    this.viewAccess = !this.viewAccess;
    this.editAccess = !this.editAccess;
  }
}
