import { IMenuItem } from '@Shared/models/IMenuItem';
import { adminSubMenu } from './level-1/admin-desk.menu.config';

const idPrefix = 'MA';
const routePrefix = '/actuarial';
export const actuarialSubMenu: IMenuItem[] = [
  {
    label: 'Operations Desk',
    icon: 'fas fa-cogs',
    subs: [
      {
        label: 'Find Policy',
        link: '/actuarial/policy-desk/find',
        id: 'MFP483',
      },
      {
        label: 'Find Agent',
        link: '/actuarial/agent-desk/find',
        id: 'MFA484',
      },
      {
        label: 'Find Client',
        link: '/actuarial/client-desk/find',
        id: 'MFC484M',
      },
      {
        label: 'Find Provider',
        id: 'MFP485M',
      },
      {
        label: 'Quotations',
        link: '/actuarial/quotation',
        id: 'MQ485',
      },
    ],
    id: 'MOD482',
  },
  {
    label: 'Modelling Desk',
    svgIcon: 'risk-modelling',
    subs: [
      {
        label: 'Probability Distribution',
        link: '/actuarial/risk/prob',
        id: 'MPD489',
      },
      {
        label: 'Experience Analysis',
        link: '/actuarial/risk/experience-analysis',
        id: 'MEA',
      },
      {
        label: 'Expense Analysis',
        subs: [
          {
            label: '',
            id: 'M497',
          },
          {
            label: '',
            id: 'M498',
          },
        ],
        id: 'MEA496',
      },
      {
        label: 'Customer Behaviour',
        subs: [
          {
            label: '',
            id: 'M500',
          },
          {
            label: '',
            id: 'M501',
          },
        ],
        id: 'MCB499',
      },
      {
        label: 'Reserves',
        subs: [
          {
            label: '',
            id: 'M503',
          },
          {
            label: '',
            id: 'M504',
          },
        ],
        id: 'MR502',
      },
      {
        label: 'Reinsurance',
        subs: [
          {
            label: '',
            id: 'M506',
          },
          {
            label: '',
            id: 'M507',
          },
        ],
        id: 'MR505',
      },
    ],
    id: 'MRMD488',
  },
  {
    label: 'Pricing Desk',
    svgIcon: 'pricing',
    subs: [
      {
        label: 'Life',
        id: 'ML509',
      },
      {
        label: 'General',
        id: 'MG510',
      },
      {
        label: 'Health',
        id: 'MH511',
      },
    ],
    id: 'MPD508',
  },
  {
    label: 'Valuation Desk',
    svgIcon: 'valuation',
    subs: [
      {
        label: 'Liability',
        subs: [
          {
            label: 'Run',
            subs: [
              {
                label: 'Valuation',
                link: '/actuarial/liability/run/valuation',
                id: 'MR516',
              },
              {
                label: 'IBNR',
                link: '/actuarial/liability/run/ibnr',
                id: 'MR517',
              },
              {
                label: 'Data',
                link: '/actuarial/liability/run/data',
                id: 'MR518',
              },
            ],
            id: 'ML514',
          },
          {
            label: 'Result',
            link: '/actuarial/liability/life/result',
            id: 'MG518',
          },
        ],
        id: 'ML513',
      },
      {
        label: 'Assets',
        subs: [
          {
            label: 'Life',
            id: 'ML521',
          },
          {
            label: 'General',
            id: 'MG522',
          },
          {
            label: 'Health',
            id: 'MH523',
          },
        ],
        id: 'MA520',
      },
    ],
    id: 'MVD512',
  },
  {
    label: 'Capital Desk',
    icon: 'fa fa-bank',
    subs: [
      {
        label: 'Regulatory',
        id: 'MR525',
      },
      {
        label: 'Economic',
        id: 'ME526',
      },
    ],
    id: 'MCD524',
  },
  {
    label: 'Workflow Desk',
    icon: 'fas fa-tasks',
    subs: [
      {
        label: 'Overview',
        link: '/actuarial/workflow-desk/overview',
        id: 'MO528',
      },
      {
        label: 'Find Workflow task',
        link: '/actuarial/workflow-desk/find',
        id: 'MFWt529',
      },
      {
        label: 'Create New',
        subs: [
          {
            label: 'Task',
            link: '/actuarial/workflow-desk/task',
            id: 'MT531',
          },
          {
            label: 'Schedule',
            link: '/actuarial/workflow-desk/schedule',
            id: 'MS532',
          },
        ],
        id: 'MCN530',
      },
    ],
    id: 'MWD527',
  },
  {
    label: 'Finance Desk',
    svgIcon: 'finance',
    subs: [
      {
        label: 'View Financial Statement',
        id: 'MVFS534',
      },
      {
        label: 'View Ledgers',
        id: 'MVL535',
      },
      {
        label: 'View Expense Allocation',
        id: 'MVEA536',
      },
      {
        label: 'Cost Centres',
        id: 'MCC537',
      },
    ],
    id: 'MFD533',
  },
  {
    label: 'Set-ups',
    icon: 'evoicon evo-setting',
    subs: [
      {
        label: 'Products',
        subs: [
          {
            label: 'Cover Code',
            link: '/actuarial/setups/product/product-cover-code',
            id: 'MCC548M',
          },
          {
            label: 'Product Code',
            link: '/actuarial/setups/product/product-code',
            id: 'MPC549M',
          },
          {
            label: 'Benefit',
            link: '/actuarial/setups/product/benefit',
            id: 'MAB550M',
          },
          {
            label: 'Benefit Plan',
            link: '/actuarial/setups/product/benefit-plan/show',
            id: 'MABP551M',
          },
          {
            label: 'Classes',
            link: '/actuarial/setups/product/classes',
            id: 'MC552M',
          },
        ],
        id: 'MP547',
      },
      {
        label: 'RC Parameters',
        id: 'MRP548',
      },
      {
        label: 'EC Parameters',
        subs: [
          {
            label: 'Cover Code',
            link: '/actuarial/setups/product-cover-code',
            id: 'MCC550',
          },
          {
            label: 'Product Code',
            link: '/actuarial/setups/product-code',
            id: 'MPC551',
          },
        ],
        id: 'MEP549',
      },

      {
        label: 'IFRS',
        subs: [
          {
            label: 'Contract Grouping',
            link: '/actuarial/setup/ifrs/contract-grouping',
            id: 'MCG539',
          },
          {
            label: 'Approach Mapping',
            link: '/actuarial/setup/ifrs/approach-mapping',
            id: 'MAM542',
          },
          {
            label: 'Coverage Period',
            id: 'MCP543',
          },
          {
            label: 'Risk Adjustment',
            link: '/actuarial/setup/ifrs/risk-adjustment',
            id: 'MRA544',
          },
          {
            label: 'Discount',
            link: '/actuarial/setup/ifrs/discount',
            id: 'MD545',
          },
          {
            label: 'Triangle',
            link: '/actuarial/setup/ifrs/triangle',
            id: 'MT546M',
          },
          {
            label: 'Parameters',
            link: '/actuarial/setup/ifrs/parameters',
            id: 'MP547M',
          },
          {
            label: 'Loss Ratios',
            link: '/actuarial/setup/ifrs/loss-ratios',
            id: 'MLR48M',
          },
        ],
        id: 'MI538',
      },
      {
        label: 'Rates',
        subs: [
          {
            label: 'General Rates',
            link: '/actuarial/setups/rates/general-rates',
            id: 'MAGR540M',
          },
          {
            label: 'Interest Rates',
            link: '/actuarial/setups/rates/interest',
            id: 'MAIR541M',
          },
          {
            label: 'Bonus Rates',
            link: '/actuarial/setups/rates/bonus-rates',
            id: 'MABR542M',
          },
          {
            label: 'Discount Rates',
            link: '/actuarial/setups/rates/discount-rates',
            id: 'MADR543M',
          },
          {
            label: 'Tax Rates',
            link: '/actuarial/setups/rates/tax-rates',
            id: 'MATR544M',
          },
          {
            label: 'Short Term Rates',
            link: '/actuarial/setups/rates/short-term-rates',
            id: 'MASTR545M',
          },
          {
            label: 'Commission Rates',
            link: '/actuarial/setups/rates/commission-rates',
            id: 'MACR546M',
          },
          {
            label: 'Mortality Rates',
            link: '/actuarial/setups/rates/mortality-rates',
            id: 'MAMR547M',
          },
          {
            label: 'Yield Rates',
            link: '/actuarial/setups/rates/yield-rates',
            id: 'MAYR548M',
          },
          {
            label: 'Commission Code',
            link: '/actuarial/setups/agents/commission-code',
            id: 'MACC549M',
          },
        ],
        id: 'MAR539M',
      },
      {
        label: 'Expense Allocation',
        id: 'MEA552',
      },
      {
        label: 'SLA',
        id: 'MS553',
      },
      {
        label: 'Workflow Task',
        link: '/actuarial/setups/task-setup/index',
        id: 'MWT554',
      },
      {
        label: 'Codes',
        id: 'MC555',
        subs: [
          {
            label: 'List Values',
            link: '/actuarial/setups/code/parameters',
            id: 'MC556M',
          },
          {
            label: 'Life Expectancy',
            link: '/actuarial/setup/codes/life-expectancy',
            id: 'MCC557M',
          },
        ],
      },
    ],
    id: 'MS546',
  },
  {
    label: 'Reports',
    icon: 'evoicon evo-blankdoc',
    subs: [
      {
        label: 'Create/Run',
        link: '/actuarial/report',
        id: 'MC557',
      },
      {
        label: 'Batch',
        link: '/actuarial/report/batch',
        id: 'MB558',
      },
    ],
    id: 'MR556',
  },
  adminSubMenu(idPrefix, routePrefix),
];
