import { ETSMenuItem, IETSMenuItem } from 'ets-fe-ng-sdk';
import { SVGIconType } from '../components/svg-icon/svg-icon.model';
import { EMenuLocation, ESubSystem, ESystem, IBtn, IconType } from './index.model';
export {
	ETSMenuItem,
	IETSMenuItemBase as IMenuItemBase,
	IETSMenuItemRaw as IMenuItemRaw,
	IETSMenuItemRawBase as IMenuItemRawBase, 
	EETSPageBtnID as EPageBtnID,
} from 'ets-fe-ng-sdk';
export class MenuItemDivider extends ETSMenuItem {
	constructor() {
		super(null);
		this.isDivider = true;
	}
}
export interface IMenuItem extends IETSMenuItem<ESystem, ESubSystem> {
	isButton?: boolean;
	isPage?: boolean;
	svgIcon?: SVGIconType;
	subs?: IMenuItem[];
	children?: { [x: string]: IMenuItem };
	childrenArr?: IMenuItem[];
	hidden?: boolean;
}
export class MenuItem extends ETSMenuItem<ESystem, ESubSystem,  EMenuLocation> {
	/** @warning Don't change value as it is bound to the usermenu config saved in the backend */
	system: ESystem;
	isButton?: boolean;
	isPage?: boolean;
	svgIcon?: SVGIconType;
	children?: { [x: string]: IMenuItem };
	childrenArr?: MenuItem[];
	_level?: number;
	hasChildren?: boolean;
	hidden?: boolean;

	constructor(menuItem: IMenuItem, parent?: IMenuItem) {
		super(menuItem as any, parent as any);
	}
	toggle() {
		this.disabled = !this.disabled;
	}
	toggleView() {
		this.viewAccess = !this.viewAccess;
		if (this.editAccess) this.viewAccess = true;
	}
	toggleCreate() {
		this.editAccess = !this.editAccess;
		if (this.editAccess) this.viewAccess = true;
	}
	allowEditAllSubMenus() {
		this.subs?.forEach((x) => x?.allowEditAllSubMenus());
		this.childrenArr?.forEach((x) => x?.allowEditAllSubMenus());
		//debugger;
		this.viewAccess = true;
		this.editAccess = true;
	}
	disableEditAllSubMenus() {
		this.subs?.forEach((x) => x?.disableEditAllSubMenus());
		this.childrenArr?.forEach((x) => x?.disableEditAllSubMenus());
		this.editAccess = false;
	}
	allowViewAllSubMenus() {
		this.subs?.forEach((x) => x?.allowViewAllSubMenus());
		this.childrenArr?.forEach((x) => x?.allowViewAllSubMenus());
		//debugger;
		this.viewAccess = true;
		this.editAccess = false;
	}
	disableViewAllSubMenus() {
		this.subs?.forEach((x) => x?.disableViewAllSubMenus());
		this.childrenArr?.forEach((x) => x?.disableViewAllSubMenus());
		this.viewAccess = false;
		this.editAccess = false;
	}
	toggleEditAllSubMenus() {
		// if (this.editAccess == undefined) return;
		// debugger;
		!this.editAccess ? this.allowEditAllSubMenus() : this.disableEditAllSubMenus();
		console.log(this.subs);
	}
	toggleViewAllSubMenus() {
		// if (this.viewAccess == undefined) return;
		// debugger
		!this.viewAccess ? this.allowViewAllSubMenus() : this.disableViewAllSubMenus();
		console.log(this.subs);
	}
	allowAuthorization() {
		this.viewAccess = true;
		this.editAccess = true;
	}
	disableAuthorization() {
		this.viewAccess = false;
		this.viewAccess = false;
	}
	toggleAuthorization() {
		this.viewAccess = !this.viewAccess;
		this.editAccess = !this.editAccess;
	}
}
 
