import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';
import { ReusableMenuItems } from '../reusable.menu.config';

export const setupSubMenu = (
  idPrefix: string,
  routePrefix: string,
  extraMenus?: { additonalCodesChildren?: IMenuItem[] },
): IMenuItem =>
  EVPatchMenuItems(idPrefix, routePrefix, [
    {
      label: 'Set-ups',
      icon: 'evoicon evo-setting',
      subs: [
        {
          label: 'Accounts',
          subs: [
            {
              label: 'Account Code',
              link: '/setups/accounts/accounts',
              id: 'MAC46',
            },
            {
              label: 'General Ledger Mapping',
              link: '/setups/accounts/general-ledger-mapping',
              id: 'MGLM47',
            },
            {
              label: 'Bank',
              subs: [
                {
                  label: 'Local Bank List',
                  link: '/setups/accounts/bank/bank',
                  id: 'MLBL49',
                },
                {
                  label: 'Sort Code',
                  link: '/setups/accounts/bank/sort-code',
                  id: 'MSC50',
                },
                {
                  label: 'Bank Accounts',
                  link: '/setups/accounts/bank/bank-accounts',
                  id: 'MBA51',
                },
                {
                  label: 'Payment Gateway',
                  link: '/setups/accounts/bank/gateway',
                  id: 'MBPG52',
                },
              ],
              id: 'MB48',
            },
            {
              label: 'Payment Outward',
              link: '/setups/accounts/payment-outward',
              id: 'MPO52',
            },
            {
              label: 'Adjustment Account Mapping',
              link: '/setups/accounts/adjustment-account-mapping',
              id: 'MAAM53',
            },
            {
              label: 'Account Ledger',
              link: '/setups/accounts/account-ledger',
              id: 'MAL54',
            },
            {
              label: 'Transaction Reason Mapping',
              link: '/setups/accounts/transaction-reason-mapping',
              id: 'MTRM56',
            },
            {
              label: 'Account Sign',
              link: '/setups/accounts/account-sign',
              id: 'MLAS57M',
            },
            {
              label: 'Financial Statement',
              subs: [
                {
                  label: 'Account Line',
                  link: '/setups/accounts/financial-statement/account-line',
                  id: 'MAL58',
                },
                {
                  label: 'Budget',
                  link: '/setups/accounts/financial-statement/budget',
                  id: 'MB55',
                },
                {
                  label: 'Financial Statement',
                  link: '/setups/accounts/financial-statement/financial-statement/index',
                  id: 'MFS60',
                },
              ],
              id: 'MFS57',
            },
            {
              label: 'Trial',
              subs: [
                {
                  label: 'Trial Balance',
                  link: '/setups/accounts/trial-balance',
                  id: 'MTB61M',
                },
                {
                  label: 'Global',
                  link: '/setups/accounts/global',
                  id: 'MG62M',
                },
              ],
              id: 'MT58',
            },
            {
              label: 'Reinsurance',
              id: 'MPRA49M',
              link: '/payment-desk/reinsurance-setup',
            },
            {
              label: 'Payout Analysis',
              id: 'MLPE50M',
              link: '/setups/accounts/payout-analysis',
            },
          ],
          id: 'MA45',
        },
        {
          label: 'Process Design',
          subs: [
            {
              label: 'Process Setup',
              link: '/setups/process-design/process-setup',
              id: 'MPS62',
            },
            {
              label: 'Process Actions',
              link: '/setups/process-design/process-actions',
              id: 'MPA64',
            },
            {
              label: 'Control',
              link: '/setups/process-design/control',
              id: 'MC63',
            },
            {
              label: 'Status',
              subs: [
                {
                  label: 'Claim',
                  link: '/setups/process-design/status/claim',
                  id: 'MC66',
                },
                {
                  label: 'Policy',
                  link: '/setups/process-design/status/policy',
                  id: 'MP67',
                },
                {
                  label: 'Payment',
                  link: '/setups/process-design/status/payment',
                  id: 'MP68',
                },
              ],
              id: 'MS65',
            },
            {
              label: 'Process SLA',
              link: '/setups/process-design/process-SLA',
              id: 'MPS69',
            },
            // {
            //   label: 'Clients',
            //   subs: [
            //     {
            //       label: 'Unique Check',
            //       id: 'MUC71',
            //     },
            //     {
            //       label: 'Mandatory',
            //       link: '/setups/process-design/clients/mandatory',
            //       id: 'MMD72',
            //     },
            //   ],
            //   id: 'MC70',
            // },
            {
              label: 'Requirements',
              subs: [
                {
                  label: 'Claims',
                  link: '/setups/process-design/requirement/claim',
                  id: 'MC74',
                },
                {
                  label: 'New Business',
                  link: '/setups/process-design/requirement/new-business',
                  id: 'MNB75',
                },
              ],
              id: 'MR73',
            },
            {
              label: 'Underwriting',
              subs: [
                {
                  label: 'Requirements',
                  link: '/setups/process-design/underwriting/requirements',
                  id: 'MR77',
                },
                {
                  label: 'Table',
                  link: '/setups/process-design/underwriting/table',
                  id: 'MT78',
                },
                {
                  label: 'Questions',
                  link: '/setups/process-design/underwriting/questions',
                  id: 'MQ79',
                },
                {
                  label: 'Loadings',
                  link: '/setups/process-design/underwriting/loadings',
                  id: 'ML80M',
                },
                // {
                //   label: 'BMI Classification',
                //   link: '/setups/process-design/underwriting/bmi',
                //   id: 'MBC81M',
                // },
                {
                  label: 'Classification Setup',
                  link: '/setups/process-design/underwriting/setup',
                  id: 'MCS82M',
                },
                {
                  label: 'Medical',
                  link: '/setups/process-design/underwriting/medical',
                  id: 'MMD83',
                },
                {
                  label: 'Health Status',
                  link: '/setups/process-design/underwriting/health-status',
                  id: 'MHS93',
                },
              ],
              id: 'MU76',
            },
            {
              label: 'Message',
              id: 'MLM82M',
              link: '/setups/process-design/message',
            },
            {
              label: 'Event',
              id: 'MLE83E',
              subs: [
                {
                  label: 'Event Logs',
                  id: 'MLEL84',
                  link: '/setups/process-design/event/logs',
                },
                {
                  label: 'Find Process Future',
                  id: 'MLFPF85',
                  link: '/setups/process-design/event/process-future',
                },
                {
                  label: 'Find Policy Future',
                  id: 'MLFPOF86',
                  link: '/setups/process-design/event/policy-future',
                },
              ],
            },
            {
              label: 'Workflows',
              id: 'MWF62',
              subs: [
                {
                  label: 'Process',
                  link: '/setups/process-design/workflows/index',
                  id: 'MWFP63',
                },
                {
                  label: 'Task Set up',
                  link: '/setups/task-setup/index',
                  id: 'MTSu132',
                },
                {
                  label: 'Notification',
                  link: '/setups/process-design/notification/index',
                  id: 'MWFN133',
                },
                {
                  label: 'Find Process',
                  link: '/setups/process-design/find-process',
                  id: 'MWFP134',
                },
                {
                  label: 'Mappings',
                  link: '/setups/process-design/workflows/mappings',
                  id: 'MWFM138',
                },
              ],
            },
          ],
          id: 'MPD61',
        },
        {
          label: 'Products',
          subs: [
            {
              label: 'Cover Code',
              link: '/setups/product/product-cover-code',
              id: 'MCC81',
            },
            {
              label: 'Insurance Tabs',
              link: '/setups/product/insurance-tabs',
              id: 'MSPIT86M',
            },
            {
              label: 'Product Code',
              link: '/setups/product/product-code',
              id: 'MPC82',
            },
            // {
            //   label: 'Benefit',
            //   link: '/setups/product/benefit',
            //   id: 'MB83M',
            // },
            {
              label: 'Benefit Plan',
              link: '/setups/product/benefit-plan',
              id: 'MBP84M',
            },
            {
              label: 'Clauses',
              link: '/setups/product/clauses',
              id: 'MLC85M',
            },
            {
              label: 'Renewal',
              link: '/setups/product/renewal',
              id: 'MR86M',
            },
          ],
          id: 'MP80',
        },
        {
          label: 'Rates',
          subs: [
            {
              label: 'General Rates',
              link: '/setups/rates/general-rates',
              id: 'MGR84',
            },
            {
              label: 'Interest Rates',
              link: '/setups/rates/interest',
              id: 'MIR85',
            },
            {
              label: 'Bonus Rates',
              link: '/setups/rates/bonus-rates',
              id: 'MBR86',
            },
            {
              label: 'Discount Rates',
              link: '/setups/rates/discount-rates',
              id: 'MDR87',
            },
            {
              label: 'Tax Rates',
              link: '/setups/rates/tax-rates',
              id: 'MTR88',
            },
            {
              label: 'Short Term Rates',
              link: '/setups/rates/short-term-rates',
              id: 'MSTR89',
            },
            {
              label: 'Commission Rates',
              link: '/setups/rates/commission-rates',
              id: 'MLCR90M',
            },
            {
              label: 'Mortality Rates',
              link: '/setups/rates/mortality-rates',
              id: 'MLMR91M',
            },
            {
              label: 'Yield Rates',
              link: '/setups/rates/yield-rates',
              id: 'MLYR92M',
            },
          ],
          id: 'MR83',
        },
        ReusableMenuItems.correspondenceSubMenu('', ''),
        {
          label: 'Code',
          subs: [
            {
              label: 'Parameters',
              link: '/setups/code/parameters',
              id: 'MP98',
            },
            {
              label: 'Premium Frequencies',
              link: '/setups/code/premfreq',
              id: 'MPF99',
            },
            {
              label: 'Premium Payment Method',
              link: '/setups/code/prempaymthd',
              id: 'MPPM100',
            },
            {
              label: 'Numbering',
              link: '/setups/code/numbering',
              id: 'MN101',
            },
            {
              label: 'Currency',
              subs: [
                {
                  label: 'Code',
                  link: '/setups/code/currency/code/index',
                  id: 'MC103',
                },
                {
                  label: 'Rate',
                  link: '/setups/code/currency/rate/start',
                  id: 'MR104',
                },
              ],
              id: 'MC102',
            },
            {
              label: 'Lottery',
              link: '/setups/code/lottery/index',
              id: 'ML105',
            },
            {
              label: 'File Format',
              link: '/setups/code/file-format',
              id: 'MFF106M',
            },
            {
              label: 'Screens',
              link: '/setups/code/screens',
              id: 'MLS107M',
            },
            {
              label: 'Life Expectancy',
              link: '/setups/code/life-expectancy',
              id: 'MLSCLE109M',
            },
            {
              label: 'Group Size Bands',
              link: '/setups/code/group-size-bands',
              id: 'MLGSB110M',
            },
            {
              label: 'Health',
              subs: [
                {
                  label: 'ICD Codes',
                  link: '/setups/code/health/icd-codes',
                  id: 'MC112',
                },
                {
                  label: 'CPT CDT',
                  link: '/setups/code/health/cpt-cdt',
                  id: 'MHCC113',
                },
              ],
              id: 'MH111',
            },
            ...(extraMenus?.additonalCodesChildren || []),
          ],
          id: 'MC97',
        },
        {
          label: 'Organization',
          subs: [
            {
              label: 'Appraisal',
              subs: [
                {
                  label: 'Individual',
                  link: '/setups/organization/individual-appraisal',
                  id: 'MI113',
                },
                {
                  label: 'Team',
                  link: '/setups/organization/team-appraisal',
                  id: 'MT114',
                },
                {
                  label: 'Sub Division',
                  link: '/setups/organization/subdivision-appraisal',
                  id: 'MSD115',
                },
                {
                  label: 'Division',
                  link: '/setups/organization/division-appraisal',
                  id: 'MD116',
                },
                {
                  label: 'Company',
                  link: '/setups/organization/company-appraisal',
                  id: 'MC117',
                },
                {
                  label: 'Criteria',
                  link: '/setups/organization/criteria-appraisal',
                  id: 'MC118',
                },
              ],
              id: 'MA112',
            },
            {
              label: 'Branches',
              link: '/setups/organization/branches',
              id: 'MB119',
            },
            {
              label: 'Companies',
              link: '/setups/organization/company',
              id: 'MC120',
            },
          ],
          id: 'MO111',
        },
        {
          label: 'Address',
          subs: [
            {
              label: 'Country',
              link: '/setups/address/country',
              id: 'MC122',
            },
            {
              label: 'Region',
              link: '/setups/address/region',
              id: 'MR123',
            },
            {
              label: 'State',
              link: '/setups/address/state',
              id: 'MS124',
            },
            {
              label: 'Town',
              link: '/setups/address/town',
              id: 'MT125',
            },
            {
              label: 'Region Mapping',
              link: '/setups/address/region-mapping',
              id: 'MRM126M',
            },
          ],
          id: 'MA121',
        },
        {
          label: 'Agents',
          subs: [
            {
              label: 'Agent Type',
              link: '/setups/agents/agent-type',
              id: 'MAT127',
            },
            {
              label: 'Band',
              link: '/setups/agents/agent-band',
              id: 'MB128',
            },
            {
              label: 'Product Grouping',
              link: '/setups/agents/product-grouping',
              id: 'MPG129',
            },
            {
              label: 'Commission Code',
              link: '/setups/agents/commission-code',
              id: 'MCC130',
            },
            {
              label: 'Agent Loan Type',
              link: '/setups/agents/agent-loan-type',
              id: 'MALT131',
            },
            {
              label: 'Mandatory Info',
              link: '/setups/agents/mandatory-info',
              id: 'MMI132',
            },
          ],
          id: 'MA126',
        },
        {
          label: 'Tariff',
          id: 'MT133',
          subs: [
            {
              label: 'Tariff',
              link: '/setups/tariff/tariff',
              id: 'MT134M',
            },
            {
              label: 'Tariff Mapping',
              link: '/setups/tariff/tariff-mapping',
              id: 'MTM135M',
            },
          ],
        },
        {
          label: 'Reinsurance',
          subs: [
            {
              label: 'Treaties',
              link: '/setups/reinsurance/treaties',
              id: 'MT135',
            },
            {
              label: 'Profit Sharing',
              link: '/setups/reinsurance/profit-sharing',
              id: 'MPS136',
            },
            {
              label: 'Treaty Exclusions',
              link: '/setups/reinsurance/treaty-exclusions',
              id: 'MTE137',
            },
          ],
          id: 'MR134',
        },
      ],
      id: 'MS44',
    },
  ])[0];
