// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Config } from '../app/configs/index.config';

// environment.apiBaseUrl = Config.APIDevelopmentServer;
env.apiBaseUrl = Config.APIStagingServer;
env.authenticate = true;
env.appRoutes = appRoutes;
env.menus = getConfigMainMenu();
// environment.apiBaseUrl = "http://localhost:8005/core";
// environment.apiBaseUrl = " http://staging.evoluticstech.com:8005/core";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.import { Config } from '../app/configs/index.config';
import { environment as env } from 'projects/evolutics-shared-lib/src/environments/environment';
import { appRoutes } from '../app/configs/app-routes-configs/app-routes.config';
import { getConfigMainMenu } from '../app/configs/menu-configs/top-menu.config';
env.versionNo = '0.0.8'; 
export const environment = env;
env.logActivities=true