import { ESubSystem, ESystem } from '@Shared/models/index.model';
import { IMenuItem } from '@Shared/models/IMenuItem';
import { PageChildren } from './children.config';

import { formatSubMenus } from '@configs/base-functions';
import { adminSubMenu } from './level-1/admin-desk.menu.config';
import { ReusableMenuItems } from './reusable.menu.config';
const system = ESystem.crm;
const idPrefix = 'MC';
const routePrefix = '/crm';
export const crmSubMenu: IMenuItem[] = [
  {
    label: 'Client Desk',
    icon: 'evoicon evo-profile',
    subs: [
      {
        label: 'Overview',
        link: '/crm/client-desk/client-crms-overview',
        id: 'MO568',
      },
      {
        label: 'Find Client',
        link: '/crm/client-desk/find',
        id: 'MFC569',
      },
      {
        label: 'Find Provider',
        id: 'MFP570M',
      },
      {
        label: 'Create New Cient',
        subs: [
          {
            label: 'Individual',
            link: '/crm/client-desk/create/individual',
            id: 'MI571',
          },
          {
            label: 'Corporate',
            link: '/crm/client-desk/create/corporate',
            id: 'MC572',
          },
          {
            label: 'Provider',
            link: '/crm/client-desk/create/provider',
            id: 'MP573',
          },
        ],
        id: 'MCNC570',
      },
      {
        label: 'Create Lead',
        link: '/crm/client-desk/lead/index',
        id: 'MCL611',
      },
      {
        label: 'Find Lead',
        link: '/crm/client-desk/lead/find',
        id: 'MFL612',
      },
    ],
    children: PageChildren.clientChildren(system),
    id: 'MCD567',
  },
  {
    label: 'Agent Desk',
    subSystem: ESubSystem.agent,
    icon: 'evoicon evo-agent',
    subs: [
      {
        label: 'Overview',
        link: '/crm/agent-desk/overview',
        id: 'MO575',
      },
      {
        label: 'Find Agent',
        link: '/crm/agent-desk/find',
        id: 'MFA576',
      },
      {
        label: 'Create New Agent',
        link: '/crm/agent-desk/create-index',
        id: 'MCNA577',
      },
    ],
    children: PageChildren.agentChildren(system),
    id: 'MAD574',
  },
  {
    label: 'Policy Desk',
    icon: 'evoicon evo-find',
    subs: [
      {
        label: 'Overview',
        link: '/crm/policy-desk/overview',
        id: 'MCO576M',
      },
      {
        label: 'Find Policy',
        link: '/crm/policy-desk/find',
        id: 'MCFP577M',
      },
    ],
    children: PageChildren.policyChildren(system),
    id: 'MCPD575M',
  },
  {
    label: 'Workflow Desk',
    icon: 'fas fa-tasks ',
    subs: [
      {
        label: 'Overview',
        link: '/crm/workflow-desk/overview',
        id: 'MCO580M',
      },
      {
        label: 'Find Workflow Task',
        link: '/crm/workflow-desk/find',
        id: 'MCFWT581M',
      },
      {
        label: 'Find Workflow Schedule',
        link: '/crm/workflow-desk/schedule/find',
        id: 'MCFWS582M',
      },
      {
        label: 'Create New',
        link: '/crm/workflow-desk/create',
        subs: [
          {
            label: 'Task',
            link: '/crm/workflow-desk/task',
            id: 'MCT583M',
          },
          {
            label: 'Schedule',
            link: '/crm/workflow-desk/schedule',
            id: 'MCS584M',
          },
        ],
        id: 'MCN585M',
      },
    ],
    children: PageChildren.workflowChildren(system),
    id: 'MCWD579M',
  },
  {
    label: 'Analytics',
    icon: 'evoicon evo-linegraph',
    subs: [
      {
        label: 'Write Report',
        link: '/crm/analytics/write-report',
        id: 'MWR579',
      },
      {
        label: 'Customer Explorer',
        link: '/crm/analytics/customer-explorer',
        id: 'MCE581',
      },
      {
        label: 'Sales Activities',
        link: '/crm/analytics/sales-activities',
        id: 'MSA582',
      },
    ],
    id: 'MA578',
  },
  {
    label: 'Communication',
    icon: 'far fa-comments ',
    subs: [
      {
        label: 'Campaign',
        link: '/crm/communication/analytics-campaign',
        subs: [
          {
            label: 'Calendar',
            link: '/crm/communication/analytics-campaign',
            id: 'MC584',
          },
          {
            label: 'Find Campaign',
            link: '/crm/communication/find-campaign',
            id: 'MFC585',
          },
          {
            label: 'Create New Campaign',
            link: '/crm/communication/campaign-create',
            id: 'MCNC586',
          },
        ],
        id: 'MC583M',
      },
    ],
    id: 'MC583',
  },
  {
    label: 'Document',
    icon: 'fa fa-file',
    subs: [
      {
        label: 'Upload',
        link: '/crm/document/add',
        id: 'MU721',
      },
      {
        label: 'Retrieve',
        link: '/crm/document/retrieve',
        id: 'MR723',
      },
      {
        label: 'Scan',
        link: '/crm/document/scan',
        id: 'MS724',
      },
    ],
    id: 'MD720',
  },
  {
    label: 'Set-ups',
    icon: 'evoicon evo-setting',
    subs: [
      {
        label: 'Client Segments',
        subs: [
          {
            label: 'Segments',
            link: '/crm/setups/client-segments/segments',
            id: 'MS589',
          },
          {
            label: 'CLV',
            link: '/crm/setups/clv',
            id: 'MC590',
          },
          {
            label: 'Lifecycle Stage',
            link: '/crm/setups/lifecycle',
            id: 'MLS591',
          },
          {
            label: 'Bands',
            link: '/crm/setups/bands',
            id: 'MB592',
          },
          {
            label: 'Groups',
            link: '/crm/setups/client-group',
            id: 'MG593',
          },
          {
            label: 'Group Size Band',
            link: '/crm/setups/group-size-band',
            id: 'MGSB594',
          },
        ],
        id: 'MCS588',
      },
      {
        label: 'Codes',
        subs: [
          {
            label: 'Parameters',
            link: '/crm/setups/code/parameters',
            id: 'MP595',
          },
          {
            label: 'Lead Classification',
            link: '/crm/setups/codes/lead-classification',
            id: 'MLC596',
          },
          {
            label: 'Marketing Event',
            link: '/crm/setups/codes/marketing-events',
            id: 'MME597',
          },
          {
            label: 'Client Stage',
            link: '/crm/setups/codes/client-stage',
            id: 'MCS598',
          },
          {
            label: 'Age Group',
            link: '/crm/setups/codes/age-group',
            id: 'MAG599',
          },
          {
            label: 'Teams',
            subs: [
              {
                label: 'Find Team',
                link: '/crm/setups/codes/teams/team/find',
                id: 'MTFT601',
              },
              {
                label: 'Find Member',
                link: '/crm/setups/codes/teams/member/find',
                id: 'MTFM602',
              },
              {
                label: 'Create Team',
                link: '/crm/setups/codes/teams/team',
                id: 'MTCT603',
              },
              {
                label: 'Create Member',
                link: '/crm/setups/codes/teams/member',
                id: 'MTCM604',
              },
              {
                label: 'Create Web Access',
                link: '/crm/setups/codes/teams/web-access',
                id: 'MTCWA605',
              },
            ],
            id: 'MT600',
          },
          {
            label: 'Client Data Score',
            link: '/crm/setups/codes/client-data-score',
            id: 'MCDS602',
          },
          {
            label: 'KYC',
            link: '/crm/setups/codes/show-kyc',
            id: 'MK603',
          },
        ],
        id: 'MC594',
      },
      ReusableMenuItems.correspondenceSubMenu(idPrefix, routePrefix),
      {
        label: 'Document',
        subs: [
          {
            label: 'Sensitivity',
            link: '/crm/document/setups/sensitivity',
            id: 'MS733',
          },
          {
            label: 'Box No',
            link: '/crm/document/setups/product-code',
            id: 'MBN734',
          },
          {
            label: 'Mapping',
            link: '/crm/document/setups/mapping',
            id: 'MM735',
          },
        ],
        id: 'MD732',
      },
      {
        label: 'Task setup',
        link: '/crm/setups/task-setup',
        id: 'MTS609',
      },
      {
        label: 'Resources',
        id: 'MTS610M',
        subs: [
          {
            label: 'Test',
            link: '/crm/setups/resources/test/index',
            id: 'MTS611M',
          },
          {
            label: 'Training',
            link: '/crm/setups/resources/training/index',
            id: 'MTS612M',
          },
          {
            label: 'Product Manuals',
            link: '/crm/setups/resources/product-manuals/index',
            id: 'MTPM613M',
          },
        ],
      },
    ],
    id: 'MS587',
  },
  {
    label: 'Sales',
    icon: 'fas fa-money-check ',
    subs: [
      {
        label: 'Calendar',
        link: '/crm/communication/analytics-campaign',
        id: 'MC613',
      },
      {
        label: 'Quotation',
        link: '/crm/sales/quotations',
        id: 'MQ614',
      },
      // {
      //   label: 'Find Scenario Premuim',
      //   link: '/crm/sales/scenario-premuim/find',
      //   id: 'MCFSP616',
      // },
      // {
      //   label: 'Scenario Quotation',
      //   link: '/crm/sales/scenario-quotation',
      //   id: 'MCSQ615M',
      // },
      {
        label: 'Resources',
        id: 'MQS610M',
        subs: [
          {
            label: 'Test',
            link: '/crm/sales/resources/test/index',
            id: 'MQS611M',
          },
          {
            label: 'Training',
            link: '/crm/sales/resources/training/index',
            id: 'MQS612M',
          },
        ],
      },
    ],
    id: 'MS610',
  },

  adminSubMenu(idPrefix, routePrefix),
];

formatSubMenus(crmSubMenu);
