import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { ReusableMenuItems } from '../reusable.menu.config';
import { PageChildren } from '../children.config';

export const reportSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Reports',
      icon: 'evoicon evo-blankdoc',
      subs: [
        {
          label: 'Create/Run',
          link: '/report',
          id: 'MC138',
        },
        {
          label: 'Batch',
          link: '/report/batch',
          id: 'MB139',
        },
        {
          label: 'Generated Reports',
          link: '/report/generated',
          id: 'MLRGR140',
        },
        ReusableMenuItems.analyticsSubMenu('', ''),
      ],
      children: PageChildren.reportChildren(),
      id: 'MR137',
    },
  ])[0];
