import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';

export const policySubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Policy Desk',
      icon: 'evoicon evo-find',
      link: '/policy-desk',
      subs: [
        {
          label: 'Overview',
          link: '/policy-desk/overview',
          id: 'MO23',
        },
        {
          label: 'Find Policy',
          link: '/policy-desk/find',
          id: 'MFP24',
        },
        {
          label: 'Batch Operations',
          link: '/policy-desk/batch-operations',
          id: 'MLPBO25',
        },
        {
          label: 'Find Quotation',
          link: '/policy-desk/find-quotation',
          id: 'MFQ26',
        },
        {
          label: 'Find Proposal',
          link: '/policy-desk/find-proposal',
          id: 'MFP27',
        },
        {
          label: 'Create Proposal',
          link: '/policy-desk/create-proposal',
          id: 'MCP28',
        },
      ],
      children: PageChildren.policyChildren(),
      id: 'MPD22',
    },
  ])[0];
