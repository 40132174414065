import { Injectable } from '@angular/core';
import { UserActivityEndpointService } from '../User/user-activity/ua-extras/user-activity-endpoint.service';
import { IUserActivity, UserActivityService as UAS } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService extends UAS {
  constructor(
    private uaES: UserActivityEndpointService,
    parent: UAS,
  ) {
    super();
    this.submissionFunc = this.uaES.submitUA;
    parent.submissionFunc = this.uaES.submitUA;
  }
  add(data: IUserActivity) {
    // debugger;
    console.log('log act', data);
    return super.add(data);
  }
}
