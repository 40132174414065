import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
export namespace ReusableMenuItems {
  export const analyticsSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
    EVPatchMenuItems(idp, routep, [
      {
        icon: 'fa fa-chart-bar',
        id: 'MA625',
        label: 'Analytics',
        subs: [
          { label: 'Customer', icon: 'fas fa-user-friends', id: 'MC626' },
          {
            label: 'Policies',
            icon: 'fas fa-file-contract',
            id: 'MP627',
            subs: [
              {
                label: 'Reins Analytics',
                link: '/analytics/policies/reinsurance',
                id: 'MRA638',
              },
              {
                label: 'Policy Maturity',
                link: '/analytics/policies/policy-maturity',
                id: 'MCA640',
              },
              {
                label: 'New Business',
                link: '/analytics/policies/business',
                id: 'MNB641',
              },
              {
                label: 'Outstanding Premium',
                link: '/analytics/policies/outstanding-premium',
                id: 'MOP642',
              },
            ],
          },
          { label: 'Prospect', icon: 'fas fa-chart-area', id: 'MP628' },
          {
            label: 'Sales',
            svgIcon: 'sales',
            id: 'MS629',
            subs: [
              {
                label: 'Commission',
                link: '/analytics/sales/commission',
                id: 'MSC630',
              },
              {
                label: 'Production',
                link: '/analytics/sales/production',
                id: 'MPR641',
              },
              {
                label: 'Pipeline Analysis',
                link: '/analytics/sales/pipeline-analysis',
                id: 'PA642',
              },
            ],
          },
          { label: 'Assets Liabilities Matching', icon: 'fas fa-car ', id: 'MALM630' },
          {
            label: 'Claims',
            icon: 'fas fa-envelope-circle-check',
            id: 'MC635',
            subs: [
              {
                label: 'Claims',
                link: '/analytics/claims/find-claims',
                id: 'MC636',
              },
              {
                label: 'Outstanding Claims',
                link: '/analytics/claims/outstanding-claims',
                id: 'MOC637',
              },
              {
                label: 'Claim Cause',
                link: '/analytics/claims/claim-cause',
                id: 'MCC638',
              },
              {
                label: 'Claim Event Age',
                link: '/analytics/claims/event-age',
                id: 'MCEA639',
              },
              {
                label: 'Sum Assured',
                link: '/analytics/claims/sum-assured',
                id: 'MSA640',
              },
              {
                label: 'Claim Time To Claim',
                link: '/analytics/claims/claim-time-to-claim',
                id: 'MCTTC641',
              },
              {
                label: 'Claim Time',
                link: '/analytics/claims/claim-time',
                id: 'MCT643',
              },
            ],
          },
          {
            label: 'Financials',
            icon: 'fas fa-money-bill-alt',
            id: 'MF632',
            subs: [
              {
                label: 'Debit Notes',
                link: '/analytics/financials/debit-notes',
                id: 'MDN635',
              },
              {
                label: 'Credit Notes',
                link: '/analytics/financials/credit-notes',
                id: 'MDN636',
              },
              {
                label: 'Performance',
                link: '/analytics/financials/performance',
                id: 'MP637',
              },
              {
                label: 'Loss Ratio',
                link: '/analytics/financials/loss-ratio',
                id: 'MLR638',
              },
              {
                label: 'Bank Accounts',
                link: '/analytics/financials/bank-accounts',
                id: 'MBA639',
              },
              {
                label: 'DAC',
                link: '/analytics/financials/dac',
                id: 'MFDAC640',
              },
              {
                label: 'UPR',
                link: '/analytics/financials/upr',
                id: 'MFUPR640',
              },
            ],
          },
        ],
      },
    ])[0];

  export const correspondenceSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
    EVPatchMenuItems(idp, routep, [
      {
        label: 'Correspondence',
        subs: [
          // {
          //   label: 'Generate',
          //   link: '/life/setups/correspondence/quotation',
          //   id: 'MQ91',
          // },
          // {
          //   label: 'Email',
          //   link: '/life/setups/correspondence/email',
          //   id: 'ME92',
          // },
          // {
          //   label: 'Print',
          //   link: '/life/setups/correspondence/print',
          //   id: 'MP93',
          // },
          // {
          //   label: 'SMS',
          //   link: '/life/setups/correspondence/sms',
          //   id: 'MS94',
          // },
          // {
          //   label: 'Feed',
          //   link: '/life/setups/correspondence/feed',
          //   id: 'MF95M',
          // },
          // {
          //   label: 'Webhook',
          //   link: '/life/setups/correspondence/webhook',
          //   id: 'MW95',
          // },
          {
            label: 'Set Up',
            link: '/setups/correspondence',
            id: 'CSU95',
          },
          {
            label: 'Template',
            link: '/setups/correspondence/template',
            id: 'MT96',
          },
          {
            label: 'Template Mapping',
            link: '/setups/correspondence/template-mapping',
            id: 'MLTM97M',
          },
        ],
        id: 'C90',
      },
    ])[0];
}
