import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';

export const workflowSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Workflow Desk',
      icon: 'fas fa-tasks ',
      link: '/workflow-desk',
      subs: [
        {
          label: 'Overview',
          link: '/workflow-desk/overview',
          id: 'MO29',
        },
        {
          label: 'Find Workflow Task',
          link: '/workflow-desk/find',
          id: 'MFWT30',
        },
        {
          label: 'Find Workflow Schedule',
          link: '/workflow-desk/schedule/find',
          id: 'MFWS31M',
        },
        {
          label: 'Create New',
          link: '/workflow-desk/create',
          subs: [
            {
              label: 'Task',
              link: '/workflow-desk/task',
              id: 'MT32',
            },
            {
              label: 'Schedule',
              link: '/workflow-desk/schedule',
              id: 'MS33',
            },
          ],
          id: 'MCN31',
        },
      ],
      children: PageChildren.workflowChildren(),
      id: 'MWD28',
    },
  ])[0];
