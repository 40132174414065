import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import {
  IDescriptionHistory,
  IWorkflowQuery,
  IWorkflowReassignment,
  IWorkflowSI,
  IWorkflowSchedule,
} from './workflow.interface';
import { environment } from '../../../../environments/environment';
import { ApiService } from '@Services/api.service';
import { HttpClient } from '@angular/common/http';
import { ITaskSetup } from '../../Setup/task-setup/task-setup-extras/task-setup.response.interface';
import { AppService } from '@Services/app.service';
import { ICodeDescription, ISearchResponse, ISearchResponse2 } from '@Shared/models/index.model';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { UsersService } from '@User/adminuser/user-extras/users.service';

const baseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class FindWorkflowService {
  workflowVal: any;
  documentList: FormGroup;
  fileData: File;
  checkSearchValue: any;
  self = this;
  constructor(
    public apiService: ApiService,
    public usersService: UsersService,
    public appS: AppService,
  ) {}

  reassignWorkflow(value: IWorkflowReassignment) {
    return this.apiService
      .put<
        {
          statusCode: number;
          message: string;
        }[]
      >(baseUrl + '/rest/workflows/reassign', { data: [value] })
      .pipe(map((r) => r?.[0]));
  }
  reset() {
    this.workflowVal = null;
    this.documentList = null;
    this.fileData = null;
  }
  getTaskCodeDescList() {
    // this.apiService.getCodes(baseUrl + '/rest/workflows/task/code').toPromise();
    return this.apiService.get<ICodeDescription[]>(baseUrl + '/rest/workflows/task/code/description');
  }
  getTaskCodeAndDescriptionInUsersCompanyByUserCode(userCode = environment.user?.code) {
    return this.apiService.get<ICodeDescription[]>(
      baseUrl +
        `/rest/workflows/task/eligible/code/${
          environment.isSalesPortal ? `${environment.webUser?.agentNo}/A` : `${userCode}/U`
        }`,
    );
  }
  getTask(code: string) {
    return this.apiService.get<ITaskSetup>(baseUrl + '/rest/workflows/task/setup/' + code);
  }
  getrefList() {
    return this.apiService
      .get(baseUrl + '/rest/codes/sub/category/REF_CATEGORY')
      .pipe(map((res) => this.apiService.sortCodes(res)));
  }

  getSourceList() {
    return this.apiService.getCodes(baseUrl + '/rest/codes/WORKFLOW/SOURCE');
  }
  getUsers() {
    return this.apiService.get(baseUrl + '/rest/users/code/full-name');
  }
  getStatusList() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/WORKFLOW_STATUS');
  }
  getAssignUser(taskcode: any, busline: any) {
    return this.apiService
      .get<{ user: string }[]>(baseUrl + '/rest/workflows/user/' + taskcode + '/' + busline)
      .pipe(map((r) => r && r[0] && r[0].user));
  }
  getAssignUsergroup(taskcode: any, busline: any) {
    return this.apiService
      .get<{ group: string }[]>(baseUrl + '/rest/workflows/user/group/' + taskcode + '/' + busline)
      .pipe(map((r) => r && r[0] && r[0].group));
  }
  getDocumentSens() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/DOCUMENT_SENSITIVITY');
  }

  getDocumentBranch() {
    return this.apiService.get(baseUrl + '/rest/branch/code/desc');
  }

  getDocumentCategory() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/DOCUMENT_CATEGORY');
  }
  getDocumentSubCategory(data) {
    return this.apiService.get(baseUrl + `/rest/codes/sub/category/DOCUMENT_SUBCATEGORY/${data}`);
  }
  getScheduleFrequeny() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/FREQUENCY');
  }
  getDefaultSla(data: any) {
    return this.apiService.get<{ originalSla }>(baseUrl + '/rest/workflows/original/sla/' + data);
  }
  getRefdetails(data: any, refno: any) {
    return this.apiService.getText(baseUrl + `/rest/document/search/details/${data}/${refno}`);
  }
  getDocumentsByParameters(data: any) {
    return this.apiService.get<any[]>(baseUrl + '/rest/document/search', data);
  }
  getDocumentsByWfNo(wfNo: string) {
    return this.apiService.get(baseUrl + '/rest/document/workflows/' + wfNo).pipe(shareReplay());
  }
  getSnoozedWorkflows(wfNo: string) {
    return this.apiService.get(baseUrl + '/rest/workflows/snooze/auth/' + wfNo).pipe(shareReplay());
  }
  postWorkflowTask(data: any) {
    return this.apiService.postFile(baseUrl + '/rest/workflows', data);
  }
  postWorkflowSchedule(data: any) {
    return this.apiService.postFile<IWorkflowSchedule>(baseUrl + '/rest/workflows/schedule', data);
  }

  getFindWorkflow(params: string) {
    return this.apiService.get(baseUrl + '/rest/workflows' + params);
  }
  getShowWorkflow(data: string) {
    return this.apiService.get<IWorkflowSI>(baseUrl + '/rest/workflows/' + data).pipe(shareReplay());
  }
  validateWfNo(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.getShowWorkflow(control?.value).pipe(
      map((data) => {
        return !!data ? null : { valid: true };
      }),
      catchError(() => of(null)),
    );
  }
  getFindWorkflowByAssigned(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?assignedBy=' + data);
  }
  getFindWorkflowDescription(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?taskDescription=' + data);
  }
  getFindWorkflowLevel(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?slaLevel=' + data);
  }
  getFindWorkflowStatus(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?status=' + data);
  }
  getFindWorkflowByWorkflowNo(data: string) {
    return this.apiService.get(baseUrl + '/rest/workflows?wfNo=' + data);
  }
  getCreatedFrom(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?createdOn=' + data);
  }
  getCreatedTo(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?createdUser=' + data);
  }
  getFindWorkflowCode(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?taskCode=' + data);
  }
  getFindWorkflowToAssigned(data: any) {
    return this.apiService.get(baseUrl + '/rest/workflows?assignedTo=' + data);
  }
  getStartOn() {
    return this.apiService.get(baseUrl + '/rest/workflows/default/start/date/time');
  }
  getLevelsList() {
    return this.apiService.get(baseUrl + '/rest/codes/code_title/SLA_LEVEL');
  }
  getErrorReasonsList() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/WF_ERROR_REASON');
  }
  getErrorSeveritiesList() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/WF_ERROR_LEVEL');
  }
  getUsersList() {
    return this.apiService.get(baseUrl + '/rest/users/id/fullname');
  }
  getUserGroupsList() {
    return this.apiService.get(baseUrl + '/rest/users/group/all');
  }
  getUserGroup(taskCode, busLine = this.appS.getCurrentSystemMetadata?.busline) {
    return this.apiService.get<{ group: string }[]>(
      baseUrl + `/rest/workflows/user/group/${taskCode}/${busLine}`,
    );
  }

  getUserGroups = () => this.usersService.getusersGroup();

  getUser() {
    return this.apiService.get(baseUrl + `/rest/users/code/full-name`);
  }

  getDescription(taskCode) {
    return this.apiService.get(baseUrl + `/rest/workflows/description/${taskCode}`);
  }

  getSnoozeBasesList() {
    return this.apiService.get(baseUrl + '/rest/codes/code_title/SNOOZE_BASIS');
  }
  getSnoozeReasonsList() {
    return this.apiService.get(baseUrl + '/rest/codes/code_title/SNOOZE_REASON');
  }
  getSnoozeDuration(wfNo: string) {
    return this.apiService.get(baseUrl + '/rest/workflows/snooze/' + wfNo);
  }
  getWorkflowStatuses() {
    return this.apiService.get(baseUrl + '/rest/codes/sub/category/WORKFLOW_STATUS');
  }
  getWorkflowHistory(wfNo: string) {
    return this.apiService.get(baseUrl + '/rest/workflows/history/' + wfNo);
  }
  getWorkflowChecklist(wfNo: string) {
    return this.apiService.get(baseUrl + '/rest/workflows/checklist/' + wfNo);
  }

  searchForWorkflow = (_query: IWorkflowQuery) => {
    const query = cloneDeep(_query);
    if (query.pageNumber) query.pageNumber--;
    return this.apiService.get<ISearchResponse<IWorkflowSI>>(baseUrl + '/rest/workflows', query).pipe(
      map((res) => {
        res.page.content.forEach((r) => {
          if (r.slaLevel == 'G') r.slaLevel = 'green';
          else if (r.slaLevel == 'R') r.slaLevel = 'red';
          else if (r.slaLevel == 'B') r.slaLevel = 'black';
          else if (r.slaLevel == 'Y') r.slaLevel = 'yellow';
        });
        return { content: res.page.content, total: res.page.totalElements };
      }),
    );
  };

  searchActiveWorkflows = (query: IWorkflowQuery) =>
    this.searchForWorkflow({ ...query, status: 'A', assignedTo: environment.userName });

  searchActiveGroupWorkflows = (query: IWorkflowQuery) =>
    this.searchForWorkflow({
      ...query,
      status: 'A',
      groupAssignedTo: environment.userGroup?.crmUserGroup?.group,
    });

  searchForWorkflowSchedule(_query: any) {
    const query = _query;
    return this.apiService
      .get<ISearchResponse>(baseUrl + '/rest/workflows/schedule/search', query)
      .pipe(catchError((e) => of(<ISearchResponse>{ page: { content: [] }, listSize: 0 })));
  }

  async getWfData(no: string) {
    return this.apiService.get<any>(baseUrl + `/rest/workflows/${no}`).toPromise();
  }

  validatewfNo = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getWfData(val)
      .then((res) => {
        if (!res) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({
          wfData: res,
        });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  updateWorkflow(wfNo, payload) {
    return this.apiService.put(baseUrl + `/rest/workflows/${wfNo}`, payload);
  }

  convertWorkflow(payload) {
    return this.apiService.postString(
      baseUrl + `/rest/workflows/convert` + this.apiService.getRequestParse(payload),
    );
  }

  /**
   * add narration
   * @param payload narration
   * @returns
   */
  addWorkflowNarration(payload: IDescriptionHistory) {
    return this.apiService.post(baseUrl + `/rest/workflows/narration/history`, payload);
  }

  /**
   * update narration
   * @param id id of the narration
   * @param payload narration
   * @returns
   */
  updateWorkflowNarration(id, payload: IDescriptionHistory) {
    return this.apiService.put(baseUrl + `/rest/workflows/narration/history/${id}`, payload);
  }
  /**
   * delete narration
   * @param id id of the narration
   * @returns
   */
  deleteWorkflowNarration(id: number) {
    return this.apiService.delete(baseUrl + `/rest/workflows/narration/history/${id}`);
  }

  /**
   * get workflow narration
   * @param wfNo
   */
  getWorkflowNarration(wfNo) {
    return this.apiService.get<ISearchResponse2<IDescriptionHistory>>(
      baseUrl + `/rest/workflows/narration/history/search?wfNo=${wfNo}`,
    );
  }

  /**
   * link workflow
   * @param primaryWfNo secondaryWfNo
   * returns workflow
   */
  linkWorkflow(primaryWfNo, secondaryWfNo) {
    return this.apiService.put(
      baseUrl +
        `/rest/workflows/merge?primaryWfNo=${primaryWfNo}&secondaryWfNo=${secondaryWfNo}&userName=${environment.userName}`,
    );
  }

  /**
   * Calls the endpoint that gets pending status summary
   * @returns
   */
  getPendingStatusSummary() {
    return this.apiService.get(baseUrl + `/rest/workflows/pending/status/summary/${environment.userName}`);
  }

  /**
   * Calls the endpoint that gets WF SLA tracker
   * @returns
   */
  getWorkflowSLATracker() {
    return this.apiService.get(baseUrl + `/rest/workflows/sla/tracker?username=${environment.userName}`);
  }

  /**
   * Calls the endpoint that searches over user months using username and year
   * @returns
   */
  searchWFOverUserMonth() {
    const year = moment(Date.now()).format('YYYY');
    return this.apiService
      .get(baseUrl + `/rest/workflows/over-user/mnth/search`, {
        username: environment.userName,
        wfYear: +year,
      })
      .pipe(map((res) => res.content));
  }

  /**
   * Calls the endpoint that searchs over user months using usergroup and year
   * @returns
   */
  searchWFOverTeamMonth() {
    const year = moment(Date.now()).format('YYYY');
    return this.apiService
      .get(baseUrl + `/rest/workflows/over-team/mnth/search`, {
        userGroup: environment.userGroup?.crmUserGroup?.group,
        wfYear: +year,
      })
      .pipe(map((res) => res.content));
  }

  /**
   * Calls the endpoint that searches team task summary using the usergroup
   * @returns
   */
  searchWFTeamOSTaskSummary() {
    return this.apiService
      .get(baseUrl + `/rest/workflows/team-os/task/summary/search`, {
        userGroup: environment.userGroup?.crmUserGroup?.group,
      })
      .pipe(map((res) => res.content));
  }

  searchWFOverUserDetail() {
    return this.apiService
      .get(baseUrl + `/rest/workflows/over-user/details/search`)
      .pipe(map((res) => res.content));
  }
  searchWFOverTeamDetails() {
    return this.apiService
      .get(baseUrl + `/rest/workflows/over-team/details/search`)
      .pipe(map((res) => res.content));
  }

  getAllProcessCodes() {
    return this.apiService
      .get<ISearchResponse2>(`${environment.apiBaseUrl}/rest/workflows/task/process/search`)
      .pipe(
        map((res) =>
          res.content.map((x) => {
            return {
              code: x.workflowTaskProcess.processCode,
              description: x.workflowTaskProcess.processName,
            };
          }),
        ),
      );
  }

  searchWFMappings = (query?: any) => {
    return this.apiService.get<ISearchResponse2>(baseUrl + '/rest/workflows/mapping/search', query);
  };

  createWFMappings = (data: any) => {
    return this.apiService.post(baseUrl + '/rest/workflows/mapping', data);
  };

  updateWFMappings = (id: string, data: any) => {
    return this.apiService.get(baseUrl + `/rest/workflows/mapping/${id}`, data);
  };

  deleteWFMappings = (id: string) => {
    return this.apiService.delete(baseUrl + `/rest/workflows/mapping/${id}`);
  };
}
