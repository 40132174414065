import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';

export const adminSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Admin',
      icon: 'evoicon evo-admin',
      subs: (
        [
          {
            label: 'User',
            subs: [
              {
                label: 'User',
                link: '/admin-desk/user',
                id: 'MU142',
              },
              {
                label: 'User Group',
                link: '/admin-desk/user/group',
                id: 'MUG143',
              },
              {
                label: 'User Menu',
                link: '/admin-desk/user/menus',
                id: 'MUM144',
              },
              {
                label: 'User Activity',
                link: '/admin-desk/user/activities',
                id: 'MUA158M',
              },
              {
                label: 'User Session',
                link: '/admin-desk/user/sessions',
                id: 'MUS159M',
              },
              {
                label: 'Web User',
                subs: [
                  {
                    label: 'Create New',
                    link: '/admin-desk/web-user',
                    id: 'MCN146',
                  },
                ],
                id: 'MWU145',
              },
            ],
            id: 'MU141',
          },
          {
            label: 'Dates',
            subs: [
              {
                label: 'Dates',
                link: '/admin-desk/dates',
                id: 'MD148',
              },
              {
                label: 'Holidays',
                link: '/admin-desk/holidays',
                id: 'MH149',
              },
              // {
              //   label: 'Holidays',
              //   subs: [
              //     {
              //       label: 'Find Holiday',
              //       link: '/admin-desk/find-holiday',
              //       id: 'MFH150M',
              //     },
              //     {
              //       label: 'Create Holiday',
              //       link: '/admin-desk/create-holiday',
              //       id: 'MCH151M',
              //     },
              //   ],
              //   id: 'MH149',
              // },
            ],
            id: 'MD147',
          },
          {
            label: 'Evolutics',
            subs: [
              {
                label: 'Tasks',
                id: 'MT169M',
                subs: [
                  {
                    label: 'Find',
                    id: 'MTF173M',
                    link: '/admin-desk/evolutics/tasks/find',
                  },
                  {
                    label: 'Create',
                    id: 'MTC174M',
                    link: '/admin-desk/evolutics/tasks/create',
                  },
                ],
                //link: '/admin-desk/evolutics/tasks',
              },
              {
                label: 'API Library',
                id: 'MAL170M',
                link: '/admin-desk/evolutics/api-library',
              },
              {
                label: 'Database Tables',
                link: '/admin-desk/evolutics/table-relations',
                id: 'MLTR108M',
              },
              {
                label: 'Table Relations',
                id: 'MDD171M',
                link: '/admin-desk/evolutics/database-dictionary',
              },
              {
                label: 'Table Fields',
                id: 'TF',
                link: '/admin-desk/evolutics/table-fields',
              },
              {
                label: 'Help Library',
                id: 'MHL172M',
                link: '/admin-desk/evolutics/help-library',
              },
              {
                label: 'Suggestions',
                id: 'MSG173M',
                subs: [
                  {
                    label: 'Find Suggestion',
                    id: 'MFSG174M',
                    link: '/admin-desk/evolutics/suggestions/find',
                  },
                  {
                    label: 'Create Suggestion',
                    id: 'MCSG175M',
                    link: '/admin-desk/evolutics/suggestions/create',
                  },
                ],
              },
            ],
            id: 'ME168',
          },
          {
            label: 'Batch',
            id: 'MB158',
            subs: [
              {
                label: 'Create Batch',
                link: '/admin-desk/createBatch',
                id: 'MCB159',
              },
              {
                label: 'Run Batch',
                link: '/admin-desk/run-batch',
                id: 'MRB160',
              },
              {
                label: 'Find Batch',
                link: '/admin-desk/find-batch',
                id: 'MBL161',
              },
              {
                label: 'Batch Log Files',
                link: '/admin-desk/batchLogFiles',
                id: 'MBLF162',
              },
              {
                label: 'Batch Setup',
                link: '/admin-desk/batch-setup',
                id: 'MBS163',
              },
              {
                label: 'Batch Uploads',
                link: '/admin-desk/batch-uploads',
                id: 'MBU152M',
              },
              {
                label: 'Direct Debit',
                link: '/admin-desk/direct-debit',
                id: 'MDD165',
              },
              {
                label: 'Batch Activities',
                link: '/admin-desk/batch/activities',
                id: 'MBO166M',
                // hidden: true,
              },
              {
                label: 'Batch Patches',
                link: '/admin-desk/batch/patches',
                id: 'MBP167M',
              },
            ],
          },
          {
            label: 'Customizations',
            subs: [
              {
                label: 'Labels',
                id: 'MT106M',
                link: '/admin-desk/customization/labels',
              },
              {
                label: 'Encryption',
                id: 'MT107M',
                link: '/admin-desk/customization/encryption',
              },
            ],
            id: 'MC105M',
          },
          // {
          //   label: 'Authorize',
          //   link: '/admin-desk/authorize/pending',
          //   id: 'MA150',
          // },
          {
            label: 'Find Endorsement',
            link: '/admin-desk/authorize/find',
            id: 'MFE151M',
          },
          {
            label: 'Translations',
            link: '/admin-desk/translations',
            // subs: [
            //   { label: 'Supported Languages', link: '/admin-desk/translations', id: 'MTSL107M' },
            //   { label: 'Glossary', link: '/admin-desk/translations/glossary', id: 'MTG108M' },
            // ],
            id: 'MT106',
          },

          {
            label: 'Interest Recalculation',
            link: '/admin-desk/intRecal',
            id: 'MIR163',
          },
          {
            label: 'Configuration',
            link: '/admin-desk/config',
            id: 'MC164',
          },
          {
            label: 'Documents',
            subs: [
              {
                label: 'Logs',
                id: 'MDL166',
                link: '/admin-desk/documents',
              },
              {
                label: 'Find Documents',
                id: 'MDFD167',
                link: '/admin-desk/find-documents',
              },
              {
                label: 'Printer Path',
                id: 'MDP168',
                link: '/admin-desk/printer',
              },
            ],
            id: 'MD165',
          },
          {
            label: 'Gateway',
            link: '/admin-desk/gateway',
            id: 'MG166',
          },
          {
            label: 'Lottery',
            link: '/admin-desk/lottery',
            id: 'M167',
          },
        ] as IMenuItem[]
      ).sort((a, b) => b.subs?.length || 0 - a.subs?.length || 0),
      id: 'MA140',
    },
  ])[0];
