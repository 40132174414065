import { ESystem, ESystemBusLine, ISystem } from '@Shared/models/index.model';
import { Config as CFG } from '@configs/index.config';
import { appRoutes } from './app-routes-configs/app-routes.config';
import { Subscription } from 'rxjs';

export class Config extends CFG {
  // 'https://ec2-18-219-154-9.us-east-2.compute.amazonaws.com:8005/core';
  // 'https://development.evoluticstech.com/core';

  // "https://dev-api-jupiter.evoluticstech.com"
  // `https://staging-api-core.evoluticstech.com/core`;
  // 'https://stagingcore.evoluticstech.com/core';
  // 'https://development.evoluticstech.com/core';
  // 'http://ec2-3-140-216-214.us-east-2.compute.amazonaws.com:30123/core'
  // 'http://ec2-18-221-76-232.us-east-2.compute.amazonaws.com:8005/core';

  // 'http://ec2-3-140-216-214.us-east-2.compute.amazonaws.com:30123/core';
  // 'https://development.evoluticstech.com/core';

  static busLineMetadataMap = new Map<ESystemBusLine, ISystem<ESystem, ESystemBusLine>>();
  static systemMetadataMap = new Map<ESystem, ISystem<ESystem, ESystemBusLine>>([
    [
      ESystem.actuarial,
      {
        name: ESystem.actuarial,
        busline: ESystemBusLine.actuarial,
        appRoute: appRoutes.act,
        rootPath: appRoutes.act.pub,
        colourCode: '#009858',
      },
    ],
    [
      ESystem.analytics,
      {
        name: ESystem.analytics,
        busline: ESystemBusLine.analytics,
        appRoute: appRoutes.analytics,
        rootPath: appRoutes.analytics.pub,
        colourCode: '#dc3545',
      },
    ],
    [
      ESystem.assets,
      {
        name: ESystem.assets,
        busline: ESystemBusLine.assets,
        appRoute: appRoutes.assets,
        rootPath: appRoutes.assets.pub,
        colourCode: '#d63384',
      },
    ],
    [
      ESystem.cash,
      {
        name: ESystem.cash,
        busline: ESystemBusLine.cash,
        appRoute: appRoutes.cash,
        rootPath: appRoutes.cash.pub,
        colourCode: '#00bd6e',
      },
    ],
    [
      ESystem.crm,
      {
        name: ESystem.crm,
        busline: ESystemBusLine.crm,
        appRoute: appRoutes.crm,
        rootPath: appRoutes.crm.pub,
        colourCode: '#c8003c',
      },
    ],
    [
      ESystem.dBCloner,
      {
        name: ESystem.dBCloner,
        busline: ESystemBusLine.dBCloner,
        appRoute: appRoutes.dbcloner,
        rootPath: null,
        colourCode: '#303952',
      },
    ],
    // [
    //   ESystem.document,
    //   {
    //     name: ESystem.document,
    //     busline: ESystemBusLine.document,
    //     appRoute: appRoutes.document,
    //     rootPath: appRoutes.document.pub,
    //     colourCode: '#0bbcc8',
    //   },
    // ],
    [
      ESystem.finance,
      {
        name: ESystem.finance,
        busline: ESystemBusLine.finance,
        appRoute: appRoutes.finance,
        rootPath: appRoutes.finance.pub,
        colourCode: '#34495e',
      },
    ],
    [
      ESystem.general,
      {
        name: ESystem.general,
        busline: ESystemBusLine.general,
        appRoute: appRoutes.general,
        rootPath: appRoutes.general.pub,
        colourCode: '#514ef5',
      },
    ],
    [
      ESystem.health,
      {
        name: ESystem.health,
        busline: ESystemBusLine.health,
        appRoute: appRoutes.health,
        rootPath: appRoutes.health.pub,
        colourCode: '#3498db',
      },
    ],
    [
      ESystem.human,
      {
        name: ESystem.human,
        busline: ESystemBusLine.human,
        appRoute: appRoutes.human,
        rootPath: appRoutes.human.pub,
        colourCode: '#cadb34',
      },
    ],
    [
      ESystem.life,
      {
        name: ESystem.life,
        busline: ESystemBusLine.life,
        appRoute: appRoutes.life,
        rootPath: appRoutes.life.pub,
        colourCode: '#5e929c',
        // colourCode: '#7b5e9e',
        // colourCode: '#ab00c8',
      },
    ],
    [
      ESystem.process,
      {
        name: ESystem.process,
        busline: ESystemBusLine.process,
        appRoute: appRoutes.process,
        rootPath: appRoutes.process.pub,
        colourCode: '#b3956d',
        // colourCode: '#7b5e9e',
        // colourCode: '#ab00c8',
      },
    ],
  ]);
}
for (const item of Config.systemMetadataMap.values()) {
  Config.busLineMetadataMap.set(item.busline, item);
}
