import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { IUserProfile } from '../Authentication/auth-extras/authentication.interface';
import { CreateUser, UserGroup } from '../Shared/models/life/user/Users';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { IUserSearchObj } from '../User/adminuser/user-extras/user.interface';
import { ISearchResponse, ISearchResponse2 } from 'ets-fe-ng-sdk';
import { UsersService } from '@User/adminuser/user-extras/users.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseURL = environment.apiBaseUrl + '/rest/users/';
  constructor(
    public apiService: ApiService,
    public sS: StorageService,
    public userS: UsersService,
  ) {}
  checkIfClientExistsBy(query: IUserSearchObj) {
    return this.apiService.get<boolean>(this.baseURL + `unique/exists`, query);
  }
  getUsersFullname = () => {
    return this.apiService.get<any[]>(`${this.baseURL}id/fullname`);
  };
  getAllUserCodeAndFullName = () => {
    return this.apiService
      .get<
        {
          fullName: string;
          userName: string;
          code: string;
        }[]
      >(`${this.baseURL}code/full-name`)
      .pipe(
        map((res) => res.sort2('userName', true)),
        catchError((err) =>
          of(
            [] as {
              fullName: string;
              userName: string;
              code: string;
            }[],
          ),
        ),
      );
  };
  getAllUserCodeAndFullNameLocal = () => {
    return this.apiService
      .getFromlocal<
        {
          fullName: string;
          userName: string;
          code: string;
        }[]
      >(`${this.baseURL}code/full-name`)
      .pipe(
        map((res) => res.sort2('userName', true)),
        catchError((err) => of([])),
      );
  };
  searchUsers = (query) =>
    this.apiService.get<ISearchResponse2<IUserProfile>>(`${this.baseURL}search`, query);

  searchUsersLocal = (query) =>
    this.apiService.getFromlocal<ISearchResponse2<IUserProfile>>(`${this.baseURL}search`, query);

  searchByName = (name: string, extras?: {}) =>
    this.searchUsers({ fullName: name, ...extras }).pipe(map((res) => res?.content?.sort2('userName', true)));
  
  searchByNameLocal = (name: string, extras?: {}) =>
    this.searchUsersLocal({ fullName: name, ...extras }).pipe(
      map((res) => res?.content?.sort2('userName', true)),
    );
  searchUsersByName = (name: string) => this.searchByName(name);

  searchRelManagerByName = (name: string) => this.searchByName(name, { relManager: true });

  searchSubRelManagerByName = (name: string) => this.searchByName(name, { subRelManager: true });

  getUserByCode = (code: string) => {
    return this.apiService.get<IUserProfile>(`${this.baseURL}user/${code}`);
  };
  getUserByUsername(userName: string) {
    return this.apiService.get<IUserProfile>(`${this.baseURL}user/username/${userName}`);
  }
  getUserByID = (id: number) => {
    return this.apiService.get<CreateUser>(`${this.baseURL}${id}`);
  };

  getuserUserGroupByGroup(group: string) {
    return this.apiService.get<UserGroup>(`${this.baseURL}group/${group}`);
  }

  /**
   * get users tier by tier code and tier category
   * @param tierCode
   * @param tierCategory
   * @returns observable
   */
  getUsersMappingTier(currCode: string, tierCode: string, tierCategory: string) {
    return this.apiService.get(`${this.baseURL}mapping/tier/${tierCode}/${tierCategory}/${currCode}`);
  }

  getUserGroups = this.userS.getusersGroup;
}
