import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';

export const groupSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Group Desk',
      icon: 'evoicon evo-menu',
      link: '/group-desk',
      subs: [
        {
          label: 'Overview',
          link: '/group-desk/overview',
          id: 'MO26',
        },
        {
          label: 'Find Group',
          link: '/group-desk/find',
          id: 'MFG27',
        },
        {
          label: 'Create New Group',
          link: '/group-desk/create',
          id: 'MFG28',
        },
      ],
      children: PageChildren.groupChildren(),
      id: 'MGD25',
    },
  ])[0];
