import { ESystem } from '@Shared/models/index.model';
import { IMenuItem } from '@Shared/models/IMenuItem';
import { formatSubMenus } from '@configs/base-functions';
import { paymentSubMenu } from './level-1/payment-desk.menu.config';
import { adminSubMenu } from './level-1/admin-desk.menu.config';
import { ReusableMenuItems } from './reusable.menu.config';
import { agentSubMenu } from './level-1/agent-desk.menu.config';
import { clientSubMenu } from './level-1/client-desk.menu.config';
import { groupSubMenu } from './level-1/group-desk.menu.config';
import { policySubMenu } from './level-1/policy-desk.menu.config';
import { quoteSubMenu } from './level-1/quotation-desk.menu.config';
import { reportSubMenu } from './level-1/report-desk.menu.config';
import { workflowSubMenu } from './level-1/workflow-desk.menu.config';
import { setupSubMenu } from './level-1/setup-desk.menu.config';
import { PageChildren } from './children.config';

const system = ESystem.general;
const idPrefix = 'MG';
const routePrefix = '/general';
export const generalSubMenu: IMenuItem[] = [
  agentSubMenu(idPrefix, routePrefix),
  clientSubMenu(idPrefix, routePrefix),
  quoteSubMenu(idPrefix, routePrefix),
  policySubMenu(idPrefix, routePrefix),
  {
    label: 'Asset Desk',
    link: '/asset-desk',
    icon: 'fas fa-car ',
    subs: [
      {
        label: 'Overview',
        link: 'asset-desk/overview',
        id: 'MO191',
      },
      {
        label: 'Find Asset',
        link: 'asset-desk/find',
        id: 'MFA192',
      },
      {
        label: 'Create new',
        link: 'asset-desk/create',
        id: 'MCn193',
      },
    ],
    children: PageChildren.assetChildren(system),
    id: 'MAD190',
  },
  groupSubMenu(idPrefix, routePrefix),
  workflowSubMenu(idPrefix, routePrefix),
  paymentSubMenu(idPrefix, routePrefix),
  setupSubMenu(idPrefix, routePrefix, {
    additonalCodesChildren: [
      {
        label: 'Vehicle',
        subs: [
          {
            label: 'Brand',
            link: '/setups/code/vehicle-brand',
            id: 'MB276',
          },
          {
            label: 'Make',
            link: '/setups/code/vehicle-make',
            id: 'MM277',
          },
          {
            label: 'Model',
            link: '/setups/code/vehicle-model',
            id: 'MM278',
          },
          {
            label: 'Cert Mgt',
            link: '/setups/code/vehicle-cert',
            id: 'MCM279',
          },
          {
            label: 'Categorisation',
            link: '/setups/code/categorisation',
            id: 'MGC280M',
          },
        ],
        id: 'MV275',
      },
      {
        label: 'Water Vessel',
        subs: [
          {
            label: 'Brand',
            link: '/setups/code/water-vessel-brand',
            id: 'MWVB268M',
          },
          {
            label: 'Make',
            link: '/setups/code/water-vessel-make',
            id: 'MWVM269M',
          },
          {
            label: 'Model',
            link: '/setups/code/water-vessel-model',
            id: 'MWVM270M',
          },
          {
            label: 'Shipping Port',
            link: '/setups/code/water-vessel-port',
            id: 'MWVSP271M',
          },
        ],
        id: 'MWV267M',
      },
    ],
  }),
  reportSubMenu(idPrefix, routePrefix),
  adminSubMenu(idPrefix, routePrefix),
];

formatSubMenus(generalSubMenu);
