import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';

export const quoteSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Quotation Desk',
      icon: 'evoicon evo-multidoc',
      subs: [
        {
          label: 'Overview',
          link: '/quotation-desk/overview',
          id: 'MO17',
        },
        {
          label: 'Find Quote',
          link: '/quotation-desk/find',
          id: 'MFQ18',
        },
        {
          label: 'Create Scenario Quote',
          link: '/quotation-desk/create',
          // subs: [
          //   {
          //     label: 'Individual',
          //     link: '/quotation-desk/create/individual',
          //     id: 'MI20',
          //   },
          //   {
          //     label: 'Group',
          //     link: '/quotation-desk/create-group-quotation',
          //     id: 'MG21',
          //   },
          // ],
          id: 'MCNQ19',
        },
        {
          label: 'Underwriting',
          link: '/quotation-desk/rating-underwriting',
          id: 'MLQR20',
        },
        {
          label: 'Batch Operations',
          link: '/quotation-desk/batch-operations',
          id: 'MLGQ19',
        },
      ],
      children: PageChildren.quotationChildren(),
      id: 'MQD16',
    }
  ])[0];
