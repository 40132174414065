import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';

export const paymentSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Payment Desk',
      icon: 'evoicon evo-debitcards',
      subs: [
        {
          label: 'Authorisations',
          subs: [
            {
              label: 'Coupon',
              link: '/payment-desk/authorize-coupon',
              id: 'MAC36',
            },
            {
              label: 'Payment Outward',
              link: '/payment-desk/authorize-payment-outward',
              id: 'MAPO39',
            },
            {
              label: 'Payment Outward Batch',
              link: '/payment-desk/batch-payment-outward',
              id: 'MAPOB40',
            },
            // {
            //   label: 'Payment Outward Batch',
            //   link: '/payment-desk/batch-payment-outward',
            //   id: 'MAPOB40',
            // },
            {
              label: 'DV',
              id: 'MDA48M',
              link: '/payment-desk/dv-authorizations',
            },
            {
              label: 'Reinsurance',
              id: 'MRA47M',
              link: '/payment-desk/reinsurance-authorizations',
            },
            // {
            //   label: 'Claim Adjustments',
            //   link: '/payment-desk/authorize-claim-adjustments',
            //   id: 'MACA43',
            // },
            {
              label: 'Quote',
              id: 'MQA45M',
              link: '/payment-desk/quote-authorization',
            },
            {
              label: 'Commission Adjustment',
              link: '/payment-desk/authorize-commission-adjustments',
              id: 'MACA42',
            },
            {
              label: 'Policy Suspense Switch',
              link: '/payment-desk/authorize-inward-suspense',
              id: 'MAISS40',
            },
            {
              label: 'Policy Account',
              link: '/payment-desk/authorize-policy-account',
              id: 'MAPA41',
            },
          ],
          id: 'MA34M',
        },
        {
          label: 'Search Payment',
          id: 'MSP36M',
          subs: [
            {
              label: 'Find Claim',
              link: '/payment-desk/find-claim',
              id: 'MFC39M',
            },
            {
              label: 'Find Coupon',
              id: 'MFC38M',
              link: '/payment-desk/find-coupon',
            },
            {
              label: 'Find Debit Note',
              link: '/payment-desk/find-debit-note',
              id: 'MFDN41M',
            },
            {
              label: 'Find Credit Note',
              link: '/payment-desk/find-credit-note',
              id: 'MFCN48M',
            },
            {
              label: 'Find Expense',
              id: 'MFE37M',
              link: '/payment-desk/find-expense',
            },
            {
              label: 'Find Loss Ratio',
              id: 'MFLR38M',
              link: '/payment-desk/find-loss-ratio',
            },
            {
              label: 'Find Payout',
              link: '/payment-desk/find-payout',
              id: 'MFP40M',
            },
            // {
            //   label: 'Find Receipt',
            //   link: '/payment-desk/find-receipt',
            //   id: 'MFAS42M',
            // },
            {
              label: 'Find Pending Annuities',
              link: '/payment-desk/pending-annuities',
              id: 'MPA44M',
            },
            {
              label: 'Find Annuity Schedule',
              link: '/payment-desk/find-annuity-schedule',
              id: 'MFAS41M',
            },
            {
              label: 'Find Commission',
              link: '/payment-desk/find-commission',
              id: 'MFC45M',
            },
            {
              label: 'PFA Inflow',
              link: '/payment-desk/pfa-inflow',
              id: 'MPFAI65M',
            },
            {
              label: 'Find Bank Payout',
              link: '/payment-desk/find-bank-payout',
              id: 'MBP46M',
            },
            {
              label: 'Find Bank Payout Details',
              link: '/payment-desk/find-bank-payout-details',
              id: 'MBPD47M',
            },
            {
              label: 'Find Payout Batch',
              link: '/payment-desk/find-payout-batch',
              id: 'MBPD48M',
            },
          ],
        },
        {
          label: 'Pending Claims',
          link: '/payment-desk/pending-claims',
          id: 'MPC37',
        },
        {
          label: 'Pending Payment Outwards',
          link: '/payment-desk/pending-payment-outwards',
          id: 'MPPO38',
        },
        {
          label: 'Pending Bank Payout',
          link: '/payment-desk/pending-bank-payout',
          id: 'MPBP39',
        },
        {
          label: 'Pending Bank Payout Feedback',
          link: '/payment-desk/pending-bank-feedback',
          id: 'MPBF47M',
        },
        {
          label: 'Pending Quotes',
          link: '/payment-desk/pending-quotes-authorization',
          id: 'MPAQ44M',
        },
        {
          label: 'Pending Control',
          link: '/payment-desk/pending-control',
          id: 'MPPPC45M',
        },
        // {
        //   label: 'Reinsurance',
        //   id: 'MPRA49M',
        //   link: '/payment-desk/reinsurance',
        // },
        {
          label: 'Receipting',
          subs: [
            {
              label: 'Overview',
              link: '/receipting-desk/overview',
              id: 'MO451M',
            },
            {
              label: 'Find Receipt',
              link: '/payment-desk/find-receipt',
              id: 'MFR452M',
            },
            {
              label: 'Unposted',
              link: '/receipting-desk/unposted',
              id: 'MU453M',
            },
            {
              label: 'Create Receipt',
              link: '/receipting-desk/create',
              id: 'MCN454M',
            },
            {
              label: 'Unreconciled',
              link: '/receipting-desk/unreconciled',
              id: 'MU455M',
            },
            {
              label: 'Direct Debit Feedback',
              link: '/receipting-desk/direct-debit-feedback',
              id: 'MDDF456M',
            },
            {
              label: 'Create Coupon',
              link: '/payment-desk/create-coupon',
              id: 'MCC35',
            },
          ],
          id: 'MRD450M',
        },
        {
          label: 'Reinsurance',
          subs: [
            {
              label: 'ReInsurance Accounts',
              link: '/payment-desk/reinsurance-accounts',
              id: 'MRIA50',
            },
            {
              label: 'Pending Reinsurance Decisions',
              id: 'MPRD46M',
              link: '/payment-desk/pending-reinsurance-decisions',
            },
            {
              label: 'Find Facultative',
              id: 'MFF47M',
              link: '/payment-desk/find-facultative',
            },
            {
              label: 'Statements',
              id: 'MSS48M',
              link: '/payment-desk/statements',
            },
            {
              label: 'Analysis',
              id: 'RA49M',
              link: '/payment-desk/reinsurance/analysis',
            },
            {
              label: 'Claims',
              id: 'RC50M',
              link: '/payment-desk/reinsurance/claims',
            },
          ],
          id: 'MRI460M',
        },
        {
          label: 'Commissions',
          subs: [
            {
              label: 'Statements',
              id: 'MSS49M',
              link: '/payment-desk/statements',
            },
            {
              label: 'Postings',
              id: 'MCP50M',
              link: '/payment-desk/commissions/postings',
            },
          ],
          id: 'MCI461M',
        },
        {
          label: 'CoInsurance Accounts',
          link: '/payment-desk/coinsurance-accounts',
          id: 'MCIA51',
        },
      ].sortByFieldLength('subs', true),
      id: 'MPD34',
    },
  ])[0];
