import { IMenuItem, MenuItem, MenuItemDivider } from '@Shared/models/IMenuItem';
import { EVPatchMenuItems } from '@configs/base-functions';
import { PageChildren } from '../children.config';
import { ESubSystem } from '@Shared/models/index.model';

export const agentSubMenu: (idPrefix: string, routePrefix: string) => IMenuItem = (idp, routep) =>
  EVPatchMenuItems(idp, routep, [
    {
      label: 'Agent Desk',
      subSystem: ESubSystem.agent,
      icon: 'evoicon evo-agent',
      link: '/agent-desk',
      subs: [
        {
          label: 'Overview',
          link: '/agent-desk/overview',
          id: 'MO3',
        },
        {
          label: 'Find Agent',
          link: '/agent-desk/find',
          id: 'MFA4',
        },
        {
          label: 'Create New Agent',
          link: '/agent-desk/create-index',
          id: 'MCNA5',
        },
        {
          label: 'Summary',
          link: '/agent-desk/summary',
          id: 'MAAS6',
        },
      ],
      children: PageChildren.agentChildren(),
      id: 'MAD2',
    },
  ])[0];
