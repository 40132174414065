import { Injectable, inject } from '@angular/core';
import { ApiService } from '@Services/api.service';
import { AppService } from '@Services/app.service';
import { ESystem } from '@Shared/models/index.model';
import { MenuItem } from '@Shared/models/IMenuItem';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import {
  IMiniUserMenuConfigDetail,
  IUserMenu,
  IUserMenuConfig,
  IUserMenuConfigDetail,
  IUserMenuConfigMap,
  IUserMenuConfigSimpleIDMap,
  MenuItemPermission,
} from './usermenu.interface';
import { forkJoin, lastValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { StorageService } from '@Services/storage.service';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { Store } from '@ngrx/store';
import { UserMenuStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/usermenu/usermenu.reducer';

@Injectable({
  providedIn: 'root',
})
export class UsermenuService {
  public apiService = inject(ApiService);
  public appS = inject(AppService);
  public sS = inject(StorageService);
  public store = inject(Store);
  
  readonly disabledByParentMessage = 'Parent menu must be enabled';
  readonly permissionField = 'isP';
  readonly filterValue = { [this.permissionField]: true };
  readonly mapDelimiter = PermissionManager.mapDelimiter;
  readonly menuConfigIndexStorageKey = PermissionManager.menuConfigIndexStorageKey;
  readonly menuConfigSimpleIndexStorageKey = PermissionManager.menuConfigSimpleIndexStorageKey;
  readonly userMenu = this.store.selectSignal(UserMenuStore.selectors.usermenu);
  // readonly userMenuConfigSimpleIDMap = this.store.selectSignal(UserMenuStore.selectors.userMenuConfigSimpleIDMap);

  get baseURL() {
    return environment.apiBaseUrl;
  }

  refreshStoredUserMenu(userMenu: IUserMenu) {
    this.store.dispatch(UserMenuStore.actions.getFromOnlineSuccess({ userMenu }));
  }
  getUserMenuFromLocal = () => this.sS.getItem$<IUserMenu>(environment.userMenuKey);

  saveUserMenuToLocal = (userMenu: IUserMenu) => this.sS.saveItem$(environment.userMenuKey, userMenu);

  generateSimpleIDMap(menuConfigs: IUserMenuConfigDetail[]) {
    const menuConfigsMap: { [menuID: string]: IMiniUserMenuConfigDetail } = {};
    for (const item of menuConfigs || []) {
      menuConfigsMap[item.slug] = {
        page: item.page,
        viewAccess: item.viewAccess,
        editAccess: item.editAccess,
      };
    }
    return menuConfigsMap;
  }

  saveMenuItemsIndexToLocal = (
    userMenuConfigMap: IUserMenuConfigMap,
    userMenuConfigSimpleIDMap: IUserMenuConfigSimpleIDMap,
  ) =>
    forkJoin([
      this.sS.saveItem$(this.menuConfigIndexStorageKey, userMenuConfigMap),
      this.sS.saveItem$(this.menuConfigSimpleIndexStorageKey, userMenuConfigSimpleIDMap),
    ]);

  getMenuItemsIndexFromLocal = () =>
    forkJoin([
      this.sS.getItem$<IUserMenuConfigMap>(this.menuConfigIndexStorageKey),
      this.sS.getItem$<IUserMenuConfigSimpleIDMap>(this.menuConfigSimpleIndexStorageKey),
    ]);

  /**@deprecated Use `backendMenuConfigsToMap2` instead */
  backendMenuConfigsToMap(menuConfigs: IUserMenuConfigDetail[]) {
    if (!menuConfigs?.length) return {};
    const menuConfigsMap: { [x: string]: IUserMenuConfigDetail } = {};
    for (const umenuItem of menuConfigs) {
      menuConfigsMap[
        umenuItem.section ? umenuItem.section + this.mapDelimiter + umenuItem.slug : umenuItem.slug
      ] = umenuItem;
    }
    console.log(menuConfigsMap);
    return menuConfigsMap;
  }

  b2cKeyer = PermissionManager.b2cKeyer;

  c2bKeyer = PermissionManager.c2bKeyer;

  backendMenuConfigsToMap2(menuConfigs: IUserMenuConfigDetail[]) {
    const menuConfigsMap: { [module: string]: { [sectorSlug: string]: IUserMenuConfigDetail } } = {};
    for (const item of menuConfigs || []) {
      if (!menuConfigsMap[item.module?.toLowerCase()]) menuConfigsMap[item.module?.toLowerCase()] = {};
      menuConfigsMap[item.module?.toLowerCase()][this.b2cKeyer(item)] = item;
    }
    return menuConfigsMap;
  }
  mapKeySelector = (menu: Partial<MenuItem>) =>
    menu.parentID ? menu.parentID + this.mapDelimiter + menu.id : menu.id;

  getUserMenuByCode(code: string) {
    let url = '/rest/users/menu/config/';
    if (environment.isSalesPortal) {
      url = '/rest/sales/menu/config/';
    }
    return this.apiService.get<IUserMenu>(this.baseURL + url + code);
  }
  getUserMenuByCodeLite(code: string) {
    let url = '/rest/users/menu-config/';
    return this.apiService.get<IUserMenu['userMenuConfig']>(this.baseURL + url + code);
  }

  getAllUserMenus = () => {
    let url = '/rest/users/menu/config/desc';
    if (environment.isSalesPortal) {
      url = '/rest/sales/menu/config/desc';
    }
    return this.apiService.get<IUserMenuConfig[]>(this.baseURL + url);
  };

  getAllModuleMenus(): MenuItem[] {
    const topMenu = cloneDeep(this.appS.topMenu);
    const childrenObjToArray = (mi: MenuItem) => {
      if (mi.children) {
        mi.childrenArr = [];
        mi.hasChildren = true;
        // debugger;
        for (const key in mi.children) {
          if (Object.prototype.hasOwnProperty.call(mi.children, key)) {
            const element = mi.children[key];
            element.id = key;
            element.parentID = mi.id;
            element.isButton = !element.isPage;
            mi.childrenArr.push(new MenuItem(element, mi as any));
          }
        }
        if (!mi.subs) mi.subs = [];
        mi.subs.push(...mi.childrenArr);
      }
      // mi.subs = mi.subs?.sort2('label', true) || undefined;
      mi.subs?.forEach((x) => {
        childrenObjToArray(x);
      });
    };
    for (const mi of topMenu) {
      childrenObjToArray(mi);
    }
    return topMenu;
    // return this.appS.topMenu?.map((x) => new MenuItemPermission(x));
  }

  //   readonly flatMenuStorageKey='flatMenuStorageKey'
  //   generateMenuFlatArray(){
  // const flatMenu:IMenuItem=
  //   }
  //   saveMenuForUserPErmissi(){
  //    this.sS.saveItemA( this.flatMenuStorageKey,this.flatMenu)
  //   }

  getModuleMenus(system: ESystem = this.appS.getCurrentSystemMetadata.name as any): MenuItemPermission[] {
    return this.appS.topMenu
      .find((x) => x.system == system)
      ?.subs?.map((x: any) => new MenuItemPermission(x));
  }

  submit(data: IUserMenu) {
    return lastValueFrom(
      this.apiService.postWithEndorsment<IUserMenu>(this.baseURL + '/rest/users/menu/config', data),
    );
  }

  submitSalesMenu(data: IUserMenu) {
    return lastValueFrom(this.apiService.post<IUserMenu>(this.baseURL + '/rest/sales/menu/config', data));
  }
}
