import { Routes } from '@angular/router';
import { IMenuItem } from '@Shared/models/IMenuItem';

export { EVFunctions } from 'ets-fe-ng-sdk';

export function EVDecorator<T = any>(args?: { requiredFields?: (keyof T)[] }) {
  return function (target: Object, propertyKey?: string | symbol, parameterIndex?: number) {
    //   debugger;
    // if (args?.requiredFields) {
    //   const missingFields: (keyof T)[] = [];
    //   for (const item of args.requiredFields)
    //     if (target[item] == undefined) missingFields.push(item);
    //   if (missingFields.length)
    //     throw `The following fields are missing ` + missingFields.join(', ');
    // }
  };
}

export function EVRequired() {
  return function (target: Object, propertyKey?: string | symbol, parameterIndex?: number) {
    const d = target['checkRequiredFields'];
    debugger;
    d();
    // if (args?.requiredFields) {
    //   const missingFields: (keyof T)[] = [];
    //   for (const item of args.requiredFields)
    //     if (target[item] == undefined) missingFields.push(item);
    //   if (missingFields.length)
    //     throw `The following fields are missing ` + missingFields.join(', ');
    // }
  };
}

export function EVMethodDecorator<T = any>(args?: {
  /**
   * Specify the fields of the class that are required at runTime
   */
  requiredFields?: (keyof T)[];
}) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const oldFunc: Function = descriptor.value.clone();

    descriptor.value = function () {
      if (args?.requiredFields) {
        const missingFields: (keyof T)[] = [];
        for (const item of args.requiredFields) if (this[item] == undefined) missingFields.push(item);
        if (missingFields.length) throw `The following fields are missing ` + missingFields.join(', ');
      }
      oldFunc.apply(this);
    };
  };
}
export function formatSubMenus(menuItems: IMenuItem[]) {
  return [];
  for (let index = 0; index < menuItems.length; index++) {
    const subMenus = menuItems[index];
    // debugger;
    subMenus.subs = subMenus.subs.sortByFieldLength('subs', true);
    for (let si = 0; si < subMenus.subs.length; si++) {
      if (subMenus.subs[si].subs)
        subMenus.subs[si].subs = subMenus.subs[si].subs.sortByFieldLength('subs', true);
    }
  }
}
export function EVPatchMenuItems(idPrefix: string, routePrefix: string, menuItems: IMenuItem[]): IMenuItem[] {
  return menuItems.map((mi) => {
    mi.id = (idPrefix || '') + (mi.id || '');
    if (mi.subs?.length) mi.subs = EVPatchMenuItems(idPrefix, routePrefix, mi.subs);
    else if (mi.link) mi.link = (routePrefix || '') + (mi.link || '');
    return mi;
  });
}

export function prefixRouteTitle(prefix: string, routes: Routes) {
  for (const item of routes) {
    if (item.data.noTitleFormat) continue;
    if (item.data.title) item.data.title = `${prefix} / ${item.data.title}`;
    // if(item)
  }
}
